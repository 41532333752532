// Angular Core
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, Injectable, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

// Others
import { NgbDateFRParserFormatter } from './_abstract/ngb-date-fr-parser-formatter';

//Interceptors
import { AuthGuard } from './_guards/auth.guard';
import { TokenInterceptor } from './_interceptors/token-interceptor.component';

//Configs
import { fr } from 'date-fns/locale';
import { MESSAGES_PIPE_FACTORY_TOKEN, MESSAGES_PROVIDER } from '@xtream/ngx-validation-errors';
import { TranslateLoader, TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NZ_I18N, fr_FR, NzI18nService } from 'ng-zorro-antd/i18n';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
const DragConfig = {
  dragStartThreshold: 0,
  pointerDirectionChangeThreshold: 5,
  zIndex: 10000
};
export function httpLoaderFactory(http: HttpClient): TranslateLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
export function translatePipeFactoryCreator(translateService: TranslateService) {
  return (detector: ChangeDetectorRef) => new TranslatePipe(translateService, detector);
}

// Routing
import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';

// Layout Service - Required
import { pagesToggleService } from './@pages/services/toggler.service';

// Shared Layout Components
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { SharedModule } from './@pages/components/shared.module';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';

// Basic Bootstrap Modules
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';

// Pages Globaly required Components - Optional
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { ProgressModule } from './@pages/components/progress/progress.module';

// Thirdparty Components / Plugins - Optional
import { QuillModule } from 'ngx-quill';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// Modules
import { pgSelectModule } from './@pages/components/select/select.module';
import { pgDatePickerModule } from './@pages/components/datepicker/datepicker.module';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { MessageModule } from './@pages/components/message/message.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { NgxValidationErrorsModule } from '@xtream/ngx-validation-errors';

// Services
import { MessageService } from './@pages/components/message/message.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { FileService } from '@services/utilities/file.service';
import { FavoriteService } from '@services/utilities/favorite.service';
import { ModalService } from '@services/utilities/modal.service';
import { SwalService } from '@services/utilities/swal.service';
import { NotifService } from '@services/utilities/notif.service';
import { CategoryService } from '@services/category.service';
import { SubcategoryService } from '@services/subcategory.service';
import { FolderService } from '@services/folder.service';
import { LinkService } from '@services/link.service';
import { DocumentService } from '@services/document.service';
import { ApiService } from '@services/utilities/api.service';
import { AuthenticationService } from '@services/utilities/authentication.service';
import { SearchService } from '@services/search.service';
import { MarketTypeService } from '@services/market-type.service';
import { UserService } from '@services/user.service';
import { AuditService } from '@services/audit.service';

// Main Components
import { NgSelectMultipleComponent } from './_components/ng-select-multiple/ng-select-multiple.component';
import { SubmitButtonComponent } from './_components/submit-button/submit-button.component';
import { ListViewComponent } from './_components/list-view/list-view.component';
import { ThumbnailComponent } from './_components/thumbnail/thumbnail.component';
import { ClickableLinkComponent } from './_components/clickable-link/clickable-link.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { DocumentationBreadcrumbComponent } from './documentation/documentation-breadcrumb/documentation-breadcrumb.component';
import { DocumentationDocumentsComponent } from './documentation/documentation-documents/documentation-documents.component';
import { DocumentationFoldersComponent } from './documentation/documentation-folders/documentation-folders.component';
import { DocumentationLinksComponent } from './documentation/documentation-links/documentation-links.component';
import { DocumentationCommentComponent } from './documentation/documentation-comment/documentation-comment.component';
import { ModalCategoryComponent } from '@modals/modal-category/modal-category.component';
import { ModalReorderCategoriesComponent } from '@modals/modal-reorder-categories/modal-reorder-categories.component';
import { ModalFolderComponent } from '@modals/modal-folder/modal-folder.component';
import { ModalLinkComponent } from './_modals/modal-link/modal-link.component';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AppWithoutSidebarComponent } from './_layouts/app-without-sidebar/app-without-sidebar.component';
import { ModalDocumentComponent } from '@modals/modal-document/modal-document.component';
import { ModalImportDocumentsComponent } from '@modals/modal-import-documents/modal-import-documents.component';
import { ModalMarketTypeComponent } from '@modals/modal-market-type/modal-market-type.component';
import { ModalVisibilityDocumentComponent } from '@modals/modal-visibility-document/modal-visibility-document.component';
import { ModalVisibilityLinkComponent } from '@modals/modal-visibility-link/modal-visibility-link.component';
import { ModalSharedLinkComponent } from '@modals/modal-shared-link/modal-shared-link.component';
import { ModalNotificationsComponent } from '@modals/modal-notifications/modal-notifications.component';
import { LoginComponent } from './_components/login/login.component';
import { GlobalSearchOverlayComponent } from './_components/global-search-overlay/global-search-overlay.component';
import { ProductsStripComponent } from './products-strip/products-strip.component';
import { MarketsTypesCheckboxesComponent } from './_components/markets-types-checkboxes/markets-types-checkboxes.component';
import { HistoryComponent } from './history/history.component';
import { TimelineComponent } from './_components/timeline/timeline.component';
import { PusherService } from '@services/pusher.service';
import { UserNotificationService } from '@services/user-notification.service';


// Hammer Config Overide
// https://github.com/angular/angular/issues/10541
@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    'pinch': { enable: false },
    'rotate': { enable: false }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    AppWithoutSidebarComponent,
    AppWithSidebarComponent,
    SidebarComponent,
    HeaderComponent,
    HorizontalMenuComponent,
    NgSelectMultipleComponent,
    SubmitButtonComponent,
    ListViewComponent,
    ThumbnailComponent,
    ClickableLinkComponent,
    DashboardComponent,
    DocumentationComponent,
    DocumentationBreadcrumbComponent,
    DocumentationDocumentsComponent,
    DocumentationFoldersComponent,
    DocumentationLinksComponent,
    DocumentationCommentComponent,
    ModalCategoryComponent,
    ModalReorderCategoriesComponent,
    ModalFolderComponent,
    ModalLinkComponent,
    ModalDocumentComponent,
    ModalImportDocumentsComponent,
    ModalMarketTypeComponent,
    ModalVisibilityDocumentComponent,
    ModalVisibilityLinkComponent,
    ModalSharedLinkComponent,
    ModalNotificationsComponent,
    LoginComponent,
    GlobalSearchOverlayComponent,
    ProductsStripComponent,
    MarketsTypesCheckboxesComponent,
    HistoryComponent,
    TimelineComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    HttpClientModule,
    SharedModule,
    ProgressModule,
    pgListViewModule,
    pgCardModule,
    pgCardSocialModule,
    RouterModule.forRoot(AppRoutes),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    pgTabsModule,
    PerfectScrollbarModule,
    pgSwitchModule,
    QuillModule.forRoot(),
    pgSelectModule,
    pgDatePickerModule,
    NgbModule,
    NzDatePickerModule,
    NzSwitchModule,
    NgSelectModule,
    DataTablesModule,
    MessageModule,
    FontAwesomeModule,
    DragDropModule,
    NzPopconfirmModule,
    NgxDropzoneModule,
    NgxValidationErrorsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    MessageService,
    AuthGuard,
    AuthenticationService,
    ApiService,
    pagesToggleService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: MESSAGES_PIPE_FACTORY_TOKEN,
      useFactory: translatePipeFactoryCreator,
      deps: [TranslateService]
    },
    {
      provide: MESSAGES_PROVIDER,
      useExisting: TranslateService
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: AppHammerConfig
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateFRParserFormatter
    },
    {
      provide: CDK_DRAG_CONFIG,
      useValue: DragConfig
    },
    {
      provide: NZ_I18N,
      useValue: fr_FR
    },
    LocalStorageService,
    SubmitButtonService,
    FileService,
    FavoriteService,
    ModalService,
    SwalService,
    NotifService,
    CategoryService,
    SubcategoryService,
    FolderService,
    LinkService,
    DocumentService,
    SearchService,
    MarketTypeService,
    UserService,
    AuditService,
    PusherService,
    UserNotificationService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private i18n: NzI18nService,
    private translateService: TranslateService
  ) {
    this.setDatepickerLanguage();
    this.translateService.setDefaultLang('fr');
  }

  setDatepickerLanguage() {
    this.i18n.setDateLocale(fr);
  }
}
