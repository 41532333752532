import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SubmitButton } from '@models/submit-button.model';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.component.html',
  styleUrls: ['./submit-button.component.scss']
})
export class SubmitButtonComponent implements OnInit {

  @Input() isValidForm: boolean;
  @Input() submitButton: SubmitButton;
  @Output() onButtonClicked = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  submitForm() {
    this.onButtonClicked.emit();
  }

}
