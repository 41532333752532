<div
  bsModal
  #modalSharedLink="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          id="dialog-sizes-name1"
          class="modal-title pull-left">
          Ajout d'un lien partagé
        </h4>
        <button
          type="button"
          class="close pull-right"
          (click)="closeModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-5">
        <ng-select
          [items]="links"
          [multiple]="false"
          [virtualScroll]="true"
          [(ngModel)]="selectedLinkId"
          placeholder="Rechercher parmi les liens existants"
          notFoundText="Aucun élément trouvé"
          bindLabel="name"
          bindValue="id">
          <ng-template
            ng-label-tmp
            let-item="item">
            <ng-container
              [ngTemplateOutlet]="ngSelectItemTemplate"
              [ngTemplateOutletContext]="{item: item, showUrl: false}">
            </ng-container>
          </ng-template>
          <ng-template
            ng-option-tmp
            let-item="item">
            <ng-container
              [ngTemplateOutlet]="ngSelectItemTemplate"
              [ngTemplateOutletContext]="{item: item, showUrl: true}">
            </ng-container>
          </ng-template>
        </ng-select>
      </div>
      <div class="modal-footer justify-content-center">
        <submit-button
          *ngIf="submitButton"
          [isValidForm]="isValidButton()"
          [submitButton]="submitButton"
          (onButtonClicked)="onAddSharedClicked()">
        </submit-button>
      </div>
    </div>
  </div>
</div>
<ng-template
  #ngSelectItemTemplate
  let-item="item"
  let-showUrl="showUrl">
  <div class="d-flex align-items-center">
    <img
      height="16"
      width="16"
      src='http://www.google.com/s2/favicons?domain={{item.url}}'>
    <span class="pl-1">{{item.name}}</span>
  </div>
  <div
    *ngIf="showUrl"
    class="form-group">
    <span class="help">{{item.url}}</span>
  </div>
</ng-template>
