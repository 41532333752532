<div
  bsModal
  #modalLink="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <ng-container *ngIf="form != null">
        <div class="modal-header">
          <h4
            id="dialog-sizes-name1"
            class="modal-title pull-left">
            {{ link ? 'Édition' : 'Ajout' }} d'un lien
          </h4>
          <button
            type="button"
            class="close pull-right"
            (click)="closeModal()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mt-4">
          <form
            autocomplete="off"
            [formGroup]="form">
            <div
              class="form-group"
              ngxValidationErrorsField>
              <label class="required">Nom</label>
              <input
                type="text"
                class="form-control input-sm"
                formControlName="name">
            </div>
            <div
              class="form-group"
              ngxValidationErrorsField>
              <label class="required">Lien</label>
              <img
                class="ml-1"
                height="16"
                width="16"
                [src]="getFavicon()">
              <input
                type="text"
                class="form-control input-sm"
                formControlName="url">
            </div>
            <div class="form-group">
              <label>Login</label>
              <input
                type="text"
                class="form-control input-sm"
                formControlName="login">
            </div>
            <div class="form-group">
              <label>Mot de passe</label>
              <input
                type="text"
                class="form-control input-sm"
                formControlName="password">
            </div>
          </form>
        </div>
        <div class="modal-footer justify-content-center">
          <submit-button
            [isValidForm]="isValidForm()"
            [submitButton]="submitButton"
            (onButtonClicked)="submitForm()">
          </submit-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
