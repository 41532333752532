import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Folder } from '@models/folder.model';
import { SubmitButton } from '@models/submit-button.model';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ApiService } from './utilities/api.service';
import { LocalStorageService } from './utilities/local-storage.service';
import { ModalService } from './utilities/modal.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';

export interface FolderFormParam {
    categoryId: number;
    subcategoryId?: number;
    folder?: Folder;
}

export interface FolderStoreParam {
    form: FormGroup;
    categoryRoute: string;
    subcategoryRoute?: string;
}

export interface FolderUpdateParam {
    id: number;
    form: FormGroup;
    categoryRoute: string;
    subcategoryRoute?: string;
}

export interface FolderIdParam {
    id: number;
    categoryRoute: string;
    subcategoryRoute?: string;
}

export interface GetForListParam {
    categoryRoute: string;
    subcategoryRoute?: string;
}

export interface GetForListFavoriteParam {
    categoryRoute?: string;
    subcategoryRoute?: string;
}

@Injectable()
export class FolderService {

    private route = '/folders';
    public submitButton: SubmitButton;
    public folders$ = new BehaviorSubject<Folder[]>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private submitButtonService: SubmitButtonService,
        private localStorageService: LocalStorageService
    ) { }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

    getForm(param: FolderFormParam): FormGroup {
        const subcategoryId = param.subcategoryId ? param.subcategoryId : null;
        const name = param.folder ? param.folder.name : '';
        return this.formBuilder.group({
            categoryId: [param.categoryId, Validators.required],
            subcategoryId: subcategoryId,
            name: [name, Validators.required]
        });
    }

    getCommentForm(folder?: Folder): FormGroup {
        return this.formBuilder.group({
            comment: folder.comment ? folder.comment : ''
        });
    }

    getForList(categoryRoute: string, subcategoryRoute?: string): void {
        this.apiService.post(this.route + '/list', {
            categoryRoute: categoryRoute,
            subcategoryRoute: subcategoryRoute,
            selectedMarketsTypes: JSON.stringify(this.localStorageService.getSelectedMarketsTypesIds())
        })
            .subscribe(
                (folders: Folder[]) => {
                    this.folders$.next(folders);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    store(param: FolderStoreParam): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, param.form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.getForList(param.categoryRoute, param.subcategoryRoute);
                    this.modalService.hideFolderModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    update(param: FolderUpdateParam): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(this.route + '/' + param.id, param.form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.getForList(param.categoryRoute, param.subcategoryRoute);
                    this.modalService.hideFolderModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    updateComment(param: FolderUpdateParam): void {
        this.apiService.put(`${this.route}/${param.id}/update-comment`, param.form.value)
            .subscribe(
                (success) => {
                    this.getForList(param.categoryRoute, param.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    pin(param: FolderIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/pin`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.getForList(param.categoryRoute, param.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    unpin(param: FolderIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/unpin`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.getForList(param.categoryRoute, param.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    delete(param: FolderIdParam): void {
        this.apiService.delete(this.route + '/' + param.id)
            .subscribe(
                (success) => {
                    this.getForList(param.categoryRoute, param.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

}
