<div class="mx-4 my-3">
  <div class="row">
    <div class="col-8">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex">
            <span class="span-large">
              <b>Documents Favoris</b>
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="documents">
        <div class="card">
          <div class="card-body">
            <div class="flex-container">
              <ng-container *ngIf="documents.length === 0">
                <span>Aucun document en favoris</span>
              </ng-container>
              <ng-container *ngFor="let doc of documents; let i = index">
                <thumbnail
                  class="m-2"
                  [index]="i"
                  [currentHoverIndex]="currentHoverDocumentIndex"
                  [document]="doc"
                  (mouseOverThumbnails)="currentHoverDocumentIndex = $event"
                  (favoriteButtonClicked)="onDocumentUnmarkAsFavorite($event)">
                </thumbnail>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-4">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex">
            <span class="span-large">
              <b>Liens Favoris</b>
            </span>
          </div>
        </div>
      </div>
      <ng-container *ngIf="links">
        <div class="card">
          <div class="card-body">
            <div class="flex-container">
              <ng-container *ngIf="links.length === 0">
                <span>Aucun lien en favoris</span>
              </ng-container>
              <ng-container *ngFor="let link of links; let i = index">
                <clickable-link
                  class="m-2"
                  [index]="i"
                  [currentHoverIndex]="currentHoverLinkIndex"
                  [link]="link"
                  (mouseOverLinks)="currentHoverLinkIndex = $event"
                  (favoriteButtonClicked)="onLinkUnmarkAsFavorite($event)">
                </clickable-link>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
