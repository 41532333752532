import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'app/@pages/components/message/message.service';

export interface NotifParam {
    type: 'success' | 'info' | 'warning' | 'danger' | 'loading';
    text: string;
    position?: 'top' | 'bottom' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
    style?: 'bar' | 'circle' | 'simple';
    duration?: number;
    pauseOnHover?: boolean;
}

export interface NotifOptions {
    position?: 'top' | 'bottom' | 'bottom-left' | 'bottom-right' | 'top-left' | 'top-right';
    style?: 'bar' | 'circle' | 'simple';
    duration?: number;
    pauseOnHover?: boolean;
}

@Injectable()
export class NotifService {

    constructor(private notif: MessageService) { }

    showNotif(param: NotifParam): void {
        this.notif.create(
            param.type,
            param.text,
            {
                Position: param.position ? param.position : 'top-right',
                Style: param.style ? param.style : 'simple',
                Duration: param.duration ? param.duration : 5000,
                PauseOnHover: param.pauseOnHover ? param.pauseOnHover : true
            }
        );
    }

    showSuccessNotif(msg: any, options?: NotifOptions): void {
        let text = '';
        if (msg != null && msg.hasOwnProperty('message')) {
            text = msg.message;
        } else if (typeof msg === 'string') {
            text = msg;
        }
        this.notif.create(
            'success',
            text,
            {
                Position: options && options.position ? options.position : 'top-right',
                Style: options && options.style ? options.style : 'simple',
                Duration: options && options.duration ? options.duration : 5000,
                PauseOnHover: options && options.pauseOnHover ? options.pauseOnHover : true
            }
        );
    }

    showErrorNotif(msg: any, options?: NotifOptions): void {
        let text = '';
        if (msg instanceof HttpErrorResponse) {
            text = msg.error.message;
        } else if (typeof msg === 'string') {
            text = msg;
        }
        this.notif.create(
            'danger',
            text,
            {
                Position: options && options.position ? options.position : 'top-right',
                Style: options && options.style ? options.style : 'simple',
                Duration: options && options.duration ? options.duration : 5000,
                PauseOnHover: options && options.pauseOnHover ? options.pauseOnHover : true
            }
        );
    }

    removeAllNotifs(): void {
        this.notif.remove();
    }

}
