<div class="mx-4 my-3">
  <ng-container *ngIf="canDisplayContent()">
    <app-documentation-breadcrumb
      [category]="category"
      [subcategory]="subcategory"
      [selectedFolder]="selectedFolder">
    </app-documentation-breadcrumb>
    <div class="row m-0">
      <div class="col-3 p-0">
        <app-documentation-folders
          [folders]="folders"
          [category]="category"
          [subcategory]="subcategory"
          [selectedFolder]="selectedFolder"
          (folderSelected)="onFolderSelected($event)">
        </app-documentation-folders>
      </div>
      <div class="col-7">
        <app-documentation-documents
          [selectedFolder]="selectedFolder"
          [category]="category"
          [subcategory]="subcategory">
        </app-documentation-documents>
      </div>
      <div class="col-2 p-0">
        <app-documentation-links
          [selectedFolder]="selectedFolder"
          [category]="category"
          [subcategory]="subcategory">
        </app-documentation-links>
        <app-documentation-comment
          [selectedFolder]="selectedFolder"
          [category]="category"
          [subcategory]="subcategory">
        </app-documentation-comment>
      </div>
    </div>
  </ng-container>
</div>
