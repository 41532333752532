import { Injectable } from '@angular/core';
import { MarketType } from '@models/market-type.model';

@Injectable()
export class LocalStorageService {

    constructor() { }

    isEditMode(): boolean {
        const isEditMode = this.retrieve('isEditMode');
        if (typeof isEditMode === 'boolean') {
            return isEditMode;
        }
        return false;
    }

    setEditMode(value: boolean) {
        this.store('isEditMode', value);
    }

    isMenuPin(): boolean {
        const isMenuPin = this.retrieve('isMenuPin');
        if (typeof isMenuPin === 'boolean') {
            return !isMenuPin;
        }
        return true;
    }

    setMenuPin(value: boolean) {
        this.store('isMenuPin', value);
    }

    isArchived(): boolean {
        const isArchived = this.retrieve('isArchived');
        if (typeof isArchived === 'boolean') {
            return isArchived;
        }
        return false;
    }

    setArchived(value: boolean) {
        this.store('isArchived', value);
    }

    getActiveRouteItem() {
        const activeRouteItem = this.retrieve('activeRouteItem');
        if (activeRouteItem && activeRouteItem.length > 0) {
            return activeRouteItem;
        }
        return null;
    }

    setActiveRouteItem(value: string) {
        this.store('activeRouteItem', value);
    }

    resetActiveRouteItem() {
        this.remove('activeRouteItem');
    }

    getSearchFolderRouteItem() {
        const searchFolderRouteItem = this.retrieve('searchFolderRouteItem');
        if (searchFolderRouteItem && searchFolderRouteItem.length > 0) {
            return searchFolderRouteItem;
        }
        return null;
    }

    setSearchFolderRouteItem(value: string) {
        this.store('searchFolderRouteItem', value);
    }

    resetSearchFolderRouteItem() {
        this.remove('searchFolderRouteItem');
    }

    getSelectedMarketsTypes() {
        const selectedMarketsTypes = this.retrieve('selectedMarketsTypes');
        if (Array.isArray(selectedMarketsTypes)) {
            return selectedMarketsTypes;
        }
        return [];
    }

    getSelectedMarketsTypesIds() {
        return this.getSelectedMarketsTypes().map((i) => { return i.id });
    }

    setSelectedMarketsTypes(selectedMarketsTypes: MarketType[]) {
        const arrayToStore = [];
        selectedMarketsTypes.sort((a, b) => (a.id < b.id ? -1 : 1));
        for (let selected of selectedMarketsTypes) {
            arrayToStore.push({
                id: selected.id,
                tllIcon: selected.tllIcon,
                name: selected.name
            });
        }
        this.store('selectedMarketsTypes', arrayToStore);
    }

    private store(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    private retrieve(key: string): any {
        return JSON.parse(localStorage.getItem(key));
    }

    private remove(key: string) {
        localStorage.removeItem(key);
    }

}
