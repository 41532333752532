import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MarketType } from '@models/market-type.model';
import { MarketTypeService } from '@services/market-type.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'markets-types-checkboxes',
  templateUrl: './markets-types-checkboxes.component.html',
  styleUrls: ['./markets-types-checkboxes.component.scss']
})
export class MarketsTypesCheckboxesComponent implements OnInit, OnDestroy {

  @Input() keyPrefix: string;
  @Input() selectedMarketsTypesIds: number[] = [];
  @Output() selectedMarketsTypesChange = new EventEmitter();
  private marketsTypes$: Subscription;
  public marketsTypes: MarketType[] = [];

  constructor(
    private marketTypeService: MarketTypeService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.marketTypeService.getAll();
  }

  ngOnDestroy() {
    this.marketsTypes$.unsubscribe();
  }

  onSelectedMarketsTypesChange(event: any) {
    if (event.target.checked) {
      this.selectedMarketsTypesIds.push(Number(event.target.value));
    } else {
      const index = this.selectedMarketsTypesIds.indexOf(Number(event.target.value));
      if (index !== -1) {
        this.selectedMarketsTypesIds.splice(index, 1);
      }
    }
    this.selectedMarketsTypesChange.emit(this.arrayIdsToObjects());
  }

  private initSubscriptions() {
    this.marketsTypes$ = this.marketTypeService.marketsTypes$.subscribe(
      (data) => {
        if (data) {
          this.marketsTypes = data;
        }
      }
    );
  }

  private arrayIdsToObjects() {
    let arrayToReturn: MarketType[] = [];
    for (let id of this.selectedMarketsTypesIds) {
      for (let marketType of this.marketsTypes) {
        if (marketType.id === id) {
          arrayToReturn.push(marketType);
        }
      }
    }
    return arrayToReturn;
  }
}
