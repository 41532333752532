import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class MarketTypeService {

    private route = '/markets-types';
    public marketsTypes$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
    ) { }


    getAll(): void {
        this.apiService.get(`${this.route}`).subscribe(
            (data: any) => {
                this.marketsTypes$.next(data);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

}
