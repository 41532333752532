<div class="mx-4 my-3">
  <div class="row">
    <div class="col-4">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex">
            <span class="span-large">
              <b>Filtres</b>
            </span>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <ng-container *ngIf="form">
            <form
              autocomplete="off"
              [formGroup]="form">
              <div class="form-group">
                <label class="required">Date de début</label>
                <nz-date-picker
                  class="d-block w-100"
                  nzPlaceHolder
                  [nzFormat]="'dd/MM/yyyy'"
                  formControlName="dateFrom">
                </nz-date-picker>
              </div>
              <div class="form-group">
                <label class="required">Date de fin</label>
                <nz-date-picker
                  class="d-block w-100"
                  nzPlaceHolder
                  [nzFormat]="'dd/MM/yyyy'"
                  formControlName="dateTo">
                </nz-date-picker>
              </div>
              <div class="form-group">
                <label>Type d'évènement</label>
                <ng-select
                  [items]="eventsTypes"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  notFoundText="Aucun élément trouvé"
                  formControlName="eventType">
                </ng-select>
              </div>
              <div class="form-group">
                <label>Type d'entité</label>
                <ng-select
                  [items]="eventsEntities"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  notFoundText="Aucun élément trouvé"
                  formControlName="eventEntity">
                </ng-select>
              </div>
              <div class="form-group">
                <label>Utilisateur</label>
                <ng-select
                  [items]="users"
                  [multiple]="false"
                  bindLabel="label"
                  bindValue="value"
                  notFoundText="Aucun élément trouvé"
                  formControlName="userId">
                </ng-select>
              </div>
            </form>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div class="card">
        <div class="card-body p-2">
          <div class="d-flex">
            <span class="span-large">
              <b>Historique</b>
            </span>
          </div>
        </div>
      </div>
      <timeline [audits]="audits"></timeline>
    </div>
  </div>
</div>
