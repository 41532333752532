import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserNotificationService } from '@services/user-notification.service';
import { PusherService } from '@services/pusher.service';
import { ModalService } from '@services/utilities/modal.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { Folder } from '@models/folder.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-notifications',
  templateUrl: './modal-notifications.component.html',
  styleUrls: ['./modal-notifications.component.scss']
})
export class ModalNotificationsComponent implements OnInit, AfterViewInit {

  @ViewChild('modalNotifications', { static: false }) childModal: ModalDirective;
  @Input() notifications: any;


  constructor(
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private userNotificationService: UserNotificationService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setNotificationsModal(this.childModal);
  }

  onModalShown() {
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  onSelectFolder(folder: Folder) {
    this.childModal.hide();
    this.localStorageService.setSearchFolderRouteItem(folder.route);
    const currentUrl = this.router.url.substring(1, this.router.url.length);
    let newUrl = '';
    if (folder.subcategory != null) {
      newUrl = `documentation/${folder.category.route}/${folder.subcategory.route}`;
    } else {
      newUrl = `documentation/${folder.category.route}`;
    }
    if (currentUrl === newUrl) {
      window.location.reload();
    } else {
      this.router.navigateByUrl(newUrl);
    }
  }

  markAsReadAll() {
    this.childModal.hide();
    this.userNotificationService.markAsReadAll();
  }

}
