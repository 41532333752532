<div
  bsModal
  #modalVisibilityDocument="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          id="dialog-sizes-name1"
          class="modal-title pull-left">
          Mise à jour de la visibilité du document
        </h4>
        <button
          type="button"
          class="close pull-right"
          (click)="closeModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-5">
        <markets-types-checkboxes
          [keyPrefix]="'modal-visibility-document'"
          [selectedMarketsTypesIds]="selectedMarketsTypesIds"
          (selectedMarketsTypesChange)="onSelectedMarketsTypesChange($event)">
        </markets-types-checkboxes>
      </div>
      <div class="modal-footer justify-content-center">
        <submit-button
          *ngIf="submitButton"
          [isValidForm]="isValidButton()"
          [submitButton]="submitButton"
          (onButtonClicked)="onUpdateVisibilityClicked()">
        </submit-button>
      </div>
    </div>
  </div>
</div>
