<ng-container *ngIf="selectedMarketsTypesIds">
  <div class="row text-center checkboxes-rounded text-selection-none">
    <div
      class="col"
      *ngFor="let marketType of marketsTypes">
      <input
        type="checkbox"
        name="marketType"
        id="'{{keyPrefix}}-checkbox-{{marketType.name}}'"
        [value]="marketType.id"
        [checked]="selectedMarketsTypesIds.includes(marketType.id)"
        (change)="onSelectedMarketsTypesChange($event)">
      <label
        class="label-checkbox-inside"
        for="'{{keyPrefix}}-checkbox-{{marketType.name}}'">
        {{ marketType.abbreviation }}
      </label>
      <div>
        <label class="label-checkbox-outside">{{ marketType.name }}</label>
      </div>
    </div>
  </div>
</ng-container>
