<ng-container *ngIf="canDisplayComment()">
  <div class="card">
    <div class="card-body p-2">
      <div class="d-flex">
        <span class="span-large">
          <b>Remarque</b>
        </span>
        <ng-container *ngIf="localStorageService.isEditMode()">
          <div class="ml-auto">
            <button
              class="btn btn-success btn-circle"
              title="Enregistrer la remarque"
              [disabled]="selectedFolder == null"
              (click)="onSaveCommentClicked()">
              <i class="fas fa-save"></i>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <ng-container *ngIf="localStorageService.isEditMode() && form">
        <form
          autocomplete="off"
          [formGroup]="form">
          <textarea
            class="form-control w-100"
            placeholder="Ajouter une remarque"
            formControlName="comment"
            rows="10"></textarea>
        </form>
      </ng-container>
      <ng-container *ngIf="!localStorageService.isEditMode() && this.selectedFolder">
        <p [innerHTML]="getPComment()"></p>
      </ng-container>
    </div>
  </div>
</ng-container>
