<div
  bsModal
  #modalReorderCategory="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          id="dialog-sizes-name1"
          class="modal-title pull-left">
          Réorganisation des catégories
        </h4>
        <button
          type="button"
          class="close pull-right"
          (click)="closeModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body mt-4">
        <div
          cdkDropList
          class="cdk-list-container"
          (cdkDropListDropped)="drop($event)">
          <div
            class="cdk-item-box"
            cdkDrag
            *ngFor="let cat of categories">
            {{ cat.name }}
            <i [class]="cat.icon + ' fa-2x pr-2'"></i>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-center">
        <submit-button
          *ngIf="submitButton"
          [isValidForm]="isValidForm()"
          [submitButton]="submitButton"
          (onButtonClicked)="submitForm()">
        </submit-button>
      </div>
    </div>
  </div>
</div>
