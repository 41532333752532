import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Folder } from '@models/folder.model';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { FormGroup } from '@angular/forms';
import { SubmitButton } from '@models/submit-button.model';
import { FolderService } from '@services/folder.service';
import { Category } from '@models/category.model';
import { Subcategory } from '@models/subcategory.model';

@Component({
  selector: 'app-modal-folder',
  templateUrl: './modal-folder.component.html',
  styleUrls: ['./modal-folder.component.scss']
})
export class ModalFolderComponent implements OnInit, AfterViewInit {

  @Input() folder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @Input() isEdit: Subcategory;
  @ViewChild('modalFolder', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  private currentFolder: Folder = null;

  constructor(
    private folderService: FolderService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setFolderModal(this.childModal);
  }

  onModalShown() {
    if (this.isEdit) {
      this.currentFolder = this.folder;
    }
    this.submitButton = this.submitButtonService.determinateSubmitButton(this.currentFolder, 'le dossier');
    this.folderService.setSubmitButton(this.submitButton);
    console.log(this.subcategory ? this.subcategory.id : null);
    this.form = this.folderService.getForm({
      categoryId: this.category.id,
      subcategoryId: this.subcategory ? this.subcategory.id : null,
      folder: this.currentFolder
    });
  }

  onModalHidden() {
    this.form = null;
    this.currentFolder = null;
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.currentFolder) {
        this.folderService.update({
          id: this.currentFolder.id,
          form: this.form,
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        });
      } else {
        this.folderService.store({
          form: this.form,
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        });
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

}
