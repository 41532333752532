import { Routes } from '@angular/router';
// Layouts
import { DashboardComponent } from './dashboard/dashboard.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { HistoryComponent } from './history/history.component';
import { ProductsStripComponent } from './products-strip/products-strip.component';
import { LoginComponent } from './_components/login/login.component';
import { AuthGuard } from './_guards/auth.guard';
import { AppWithSidebarComponent } from './_layouts/app-with-sidebar/app-with-sidebar.component';
import { AppWithoutSidebarComponent } from './_layouts/app-without-sidebar/app-without-sidebar.component';


export const AppRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppWithSidebarComponent,
    children: [
      {
        path: 'accueil',
        component: DashboardComponent
      },
      {
        path: 'prestations',
        component: ProductsStripComponent
      },
      {
        path: 'historique',
        component: HistoryComponent
      },
      {
        path: 'documentation/:cat',
        component: DocumentationComponent
      },
      {
        path: 'documentation/:cat/:subcat',
        component: DocumentationComponent
      },
    ],
  },
  {
    path: '',
    component: AppWithoutSidebarComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
    ]
  },
];
