import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ng-select-multiple',
  templateUrl: './ng-select-multiple.component.html',
  styleUrls: ['./ng-select-multiple.component.scss']
})
export class NgSelectMultipleComponent implements OnInit {

  @Input() class: string;
  @Input() items: any[];
  @Input() selectedNgModel: any;
  @Input() maxSelectedItems: number;
  @Input() selectAllText: string;
  @Input() deselectAllText: string;
  @Input() countSelectedText: string;
  @Input() placeholder: string;

  constructor() { }

  ngOnInit() {
  }

  onSelectAll() {
    this.selectedNgModel = this.items;
  }

  onDeselectAll() {
    this.selectedNgModel = [];
  }

  clearSelectItem(callback: Function, arg: any) {
    callback(arg);
  }

}
