<div class="mx-4 my-3">
  <div class="card">
    <div class="card-body p-2">
      <div class="d-flex">
        <span class="span-large">
          <b>Prestations proposées</b>
        </span>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="offers-timeline-container">
        <ul class="offers-timeline offers-timeline-mi">
          <li
            class="offers-timeline-section"
            *ngFor="let step of steps">
            <header class="offers-timeline-header">
              <h2 class="offers-timeline-h2">{{ step.number }}</h2>
              <h3 class="offers-timeline-h3 h3-top">{{ step.prefix }}</h3>
              <h3 class="offers-timeline-h3">{{ step.suffix }}</h3>
              <div
                class="offers-timeline-arrow"
                style="height: 60px">
                <div
                  class="area"
                  [style.background]="step.backgroundColor">
                  <div class="arrow-back"></div>
                  <div
                    class="arrowhead"
                    [style.border-left-color]="step.backgroundColor"></div>
                  <div
                    class="vertical-line"
                    [style.background-color]="step.backgroundColor"></div>
                  <div
                    class="horizontal-line"
                    [style.background-color]="step.backgroundColor"></div>
                  <div class="circle"></div>
                </div>
              </div>
            </header>
            <div>
              <ul class="offers-timeline-list">
                <li
                  class="offers-timeline-item"
                  *ngFor="let tool of step.tools">
                  <div
                    class="pt-2"
                    style="margin-right: 0.5rem; margin-bottom: 0.15rem"
                    data-toggle="tooltip"
                    data-placement="auto"
                    data-html="true">
                    <img
                      class="img-clickable"
                      src="assets/{{tool.image}}"
                      (click)="redirectTo(tool.url)">
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <div
          class="row"
          style="margin-left: 59%;">
          <div class="seven-horizontal-line-tools-first-part"></div>
          <div
            class="pt-2"
            style="margin-right: 0.5rem; margin-bottom: 0.15rem"
            data-toggle="tooltip"
            data-placement="auto"
            data-html="true">
            <img
              class="img-clickable-under-frieze"
              src="assets/img/tools/seven.png"
              (click)="redirectTo('https://telechargement.pmbsoftware.fr/')">
          </div>
          <div class="seven-horizontal-line-tools-last-part">
            <div class="arrowhead"></div>
            <div></div>
          </div>
        </div>
        <div class="row">
          <div class="horizontal-line-tools-first-part"></div>
          <div
            class="pt-2"
            style="margin-right: 0.5rem; margin-bottom: 0.15rem"
            data-toggle="tooltip"
            data-placement="auto"
            data-html="true">
            <img
              class="img-clickable-abm"
              src="assets/img/tools/logo_abm.png"
              (click)="redirectTo('https://technique.abmec.fr/')">
          </div>
          <div class="horizontal-line-tools-last-part">
            <div class="arrowhead"></div>
            <div></div>
          </div>
        </div>
        <div class="row">
          <div class="horizontal-line-tools-first-part"></div>
          <div
            class="pt-2"
            style="margin-right: 0.5rem; margin-bottom: 0.15rem"
            data-toggle="tooltip"
            data-placement="auto"
            data-html="true">
            <img
              class="img-clickable-under-frieze"
              src="assets/img/tools/consteo.png"
              (click)="redirectTo('https://consteo.fr/')">
          </div>
          <div class="horizontal-line-tools-last-part">
            <div class="arrowhead"></div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
