import { Component, OnInit } from '@angular/core';

export interface Steps {
  number: string;
  prefix: string;
  suffix: string;
  backgroundColor: string;
  tools?: Tools[];
}

export interface Tools {
  number: string;
  image: string;
  url: string;
}

@Component({
  selector: 'app-products-strip',
  templateUrl: './products-strip.component.html',
  styleUrls: ['./products-strip.component.scss']
})
export class ProductsStripComponent implements OnInit {
  public steps: Steps[] = [];

  constructor() { }

  ngOnInit() {
    this.initSteps();
  }

  initSteps() {
    this.steps = [{
      number: "01",
      prefix: "AVANT PERMIS",
      suffix: "DE CONSTRUIRE",
      backgroundColor: "#c6c6c6",
      tools: [{
        number: "01",
        image: "img/tools/visiterre.png",
        url: "https://consteo.fr/produit/visiterre"
      },
      {
        number: "01",
        image: "img/tools/easy-commerce.PNG",
        url: "https://easycommerce.abmec.fr/"
      }
      ]
    },
    {
      number: "02",
      prefix: "PERMIS",
      suffix: "DE CONSTRUIRE",
      backgroundColor: "#b2b2b2",
      tools: [{
        number: "02",
        image: "img/tools/easy-bbio.png",
        url: "https://telechargement.pmbsoftware.fr/"
      },
      ]
    },
    {
      number: "03",
      prefix: "MISE AU POINT",
      suffix: "TECHNIQUE",
      backgroundColor: "#9d9d9c",
      tools: [{
        number: "03",
        image: "img/tools/be-in-home.png",
        url: "https://be-in-home.fr/"
      }
      ]
    },
    {
      number: "04",
      prefix: "DÉBUT",
      suffix: "DE CONSTRUIRE",
      backgroundColor: "#878787"
    },
    {
      number: "05",
      prefix: "FIN",
      suffix: "CONSTRUCTION",
      backgroundColor: "#706f6f"
    },
    ]
  }

  redirectTo(url: string) {
    window.open(url, "_blank");
  }
}
