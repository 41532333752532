import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable()
export class FileService {

    constructor(
        private apiService: ApiService
    ) { }

    public getBlob(url: string): Observable<Blob> {
        return this.apiService.getFile('/file/download/' + url);
    }

}
