import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Document } from '@models/document.model';
import { MarketType } from '@models/market-type.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { DocumentService } from '@services/document.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-visibility-document',
  templateUrl: './modal-visibility-document.component.html',
  styleUrls: ['./modal-visibility-document.component.scss']
})
export class ModalVisibilityDocumentComponent implements OnInit, AfterViewInit {

  @Input() document: Document;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @ViewChild('modalVisibilityDocument', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;
  public selectedMarketsTypesIds: number[] = [];

  constructor(
    private documentService: DocumentService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setDocumentVisibilityModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'le document',
      icon: faSync
    });
    this.documentService.setVisibilitySubmitButton(this.submitButton);
    this.selectedMarketsTypesIds = this.document.marketsTypes.map((i) => { return i.id });
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  onSelectedMarketsTypesChange(marketsTypes: MarketType[]) {
    this.selectedMarketsTypesIds = marketsTypes.map((i) => { return i.id });;
  }

  onUpdateVisibilityClicked() {
    if (this.isValidButton()) {
      this.documentService.setVisibility({
        id: this.document.id,
        list: {
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      }, this.selectedMarketsTypesIds);
    }
  }

  isValidButton() {
    return this.selectedMarketsTypesIds && this.selectedMarketsTypesIds.length > 0;
  }

}
