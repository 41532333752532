import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

// Your NgbDateFRParserFormater extends from NgbDateParserFormatter
// Is a Injectable that have two functions
@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct {
        const date = value.split('/');
        return {
            year: this.stringToNumber(date[2]),
            month: this.stringToNumber(date[1]),
            day: this.stringToNumber(date[0])
        };
    }

    format(date: NgbDateStruct): string {
        if (date != null) {
            return this.getNumberStringFormatted(date.day) +
                '/' + this.getNumberStringFormatted(date.month) +
                '/' + date.year;
        } else {
            return '';
        }
    }

    private stringToNumber(value: string): number {
        return Number(value);
    }

    private getNumberStringFormatted(value: number): string {
        if (value < 10) {
            return '0' + value;
        } else {
            return '' + value;
        }
    }
}
