import { Injectable } from '@angular/core';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { NotifService } from './notif.service';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class FavoriteService {
    public favorites$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private localSotrageService: LocalStorageService
    ) { }

    getFavorites() {
        this.apiService.post(`/favorites`, {
            'selectedMarketsTypes': JSON.stringify(this.localSotrageService.getSelectedMarketsTypesIds())
        }).subscribe(
            (data: any) => {
                this.favorites$.next(data);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getFavoriteIcon(isFavorite: boolean) {
        return isFavorite ? faStarSolid : faStarRegular;
    }

    getFavoriteIconColor(isFavorite: boolean) {
        return isFavorite ? '#FFD700' : '';
    }

    getFavoriteIconTooltip(isFavorite: boolean) {
        return isFavorite ? 'Retirer des favoris' : 'Ajouter aux favoris';
    }

}
