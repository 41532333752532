import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subcategory } from '@models/subcategory.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class SubcategoryService {

    private route = '/subcategories';
    public subcategory$ = new BehaviorSubject<Subcategory>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
    ) { }

    getForm(subcategory?: Subcategory): FormGroup {
        const id = subcategory && subcategory.id > 0 ? subcategory.id : subcategory.rowId;
        const name = subcategory ? subcategory.name : '';
        const abbreviation = subcategory ? subcategory.abbreviation : '';
        return this.formBuilder.group({
            id: [id, Validators.required],
            name: [name, Validators.required],
            abbreviation: [abbreviation, [Validators.required, Validators.minLength(2), Validators.maxLength(2)]]
        });
    }

    getByRoute(route: string): void {
        this.apiService.get(`${this.route}/route/${route}`).subscribe(
            (subcategory: Subcategory) => {
                this.subcategory$.next(subcategory);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

}
