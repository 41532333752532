<button
  class="btn btn-success btn-lg btn-icon-left"
  [disabled]="!isValidForm || !submitButton.enabled"
  (click)="submitForm()">
  <fa-icon
    [icon]="submitButton.icon"
    [pulse]="!submitButton.enabled">
  </fa-icon>
  <span>{{ submitButton.text }}</span>
</button>
