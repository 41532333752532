import { Injectable } from '@angular/core';
import Swal, { SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

export interface SwalParam {
    text: string;
    title?: string;
    options?: SweetAlertOptions;
    icon?: SweetAlertIcon;
}

@Injectable()
export class SwalService {

    constructor() { }

    showSwal(param: SwalParam): void {
        Swal.fire({
            title: param.title ? param.title : 'Info',
            text: param.text ? param.text : '',
            icon: param.icon ? param.icon : 'info'
        });
    }

    showSwalSuccess(msg: any, title?: string): void {
        let text = '';
        if (msg != null && msg.hasOwnProperty('message')) {
            text = msg.message;
        } else if (typeof msg === 'string') {
            text = msg;
        }
        Swal.fire({
            title: title ? title : 'Succès !',
            text: text,
            icon: 'success'
        });
    }

    showSwalError(msg: any, title?: string): void {
        let text = '';
        if (msg instanceof HttpErrorResponse) {
            text = msg.error.message;
        } else if (typeof msg === 'string') {
            text = msg;
        }
        Swal.fire({
            title: title ? title : 'Erreur !',
            text: text,
            icon: 'error'
        });
    }

    showSwalLoading(text?: string): void {
        Swal.fire({
            title: 'Veuillez patienter',
            text: text ? text : '',
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            onOpen: () => {
                Swal.showLoading()
            }
        });
    }

    closeSwal() {
        Swal.close();
    }

    getSwalConfirmOptions(param: SwalParam): SweetAlertOptions {
        return {
            title: param.title ? param.title : 'Êtes-vous sûr(e) ?',
            text: param.text ? param.text : '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, confirmer',
            cancelButtonText: 'Non, annuler',
            confirmButtonColor: '#449E48',
            cancelButtonColor: '#999',
        };
    }

    getSwalDeleteOptions(param: SwalParam): SweetAlertOptions {
        return {
            title: param.title ? param.title : 'Êtes-vous sûr(e) ?',
            text: param.text ? param.text : '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Oui, supprimer',
            cancelButtonText: 'Non, annuler',
            confirmButtonColor: '#dd6b55',
            cancelButtonColor: '#999',
        };
    }

}
