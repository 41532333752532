import Swal from 'sweetalert2';
import { Component, Input, OnInit } from '@angular/core';
import { Link } from '@models/link.model';
import { ModalService } from '@services/utilities/modal.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { SwalService } from '@services/utilities/swal.service';
import { Folder } from '@models/folder.model';
import { Category } from '@models/category.model';
import { Subcategory } from '@models/subcategory.model';
import { LinkService } from '@services/link.service';

@Component({
  selector: 'app-documentation-links',
  templateUrl: './documentation-links.component.html',
  styleUrls: ['./documentation-links.component.scss']
})
export class DocumentationLinksComponent implements OnInit {

  @Input() selectedFolder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  public currentHoverIndex = -1;
  public selectedLink: Link = null;

  constructor(
    public localStorageService: LocalStorageService,
    private linkService: LinkService,
    private modalService: ModalService,
    private swalService: SwalService
  ) { }

  ngOnInit() {
  }

  getLinks() {
    if (this.selectedFolder) {
      return this.selectedFolder.links;
    } else {
      return [];
    }
  }

  onAddLinkClicked() {
    this.selectedLink = null;
    this.modalService.showLinkModal();
  }

  onAddSharedLinkClicked() {
    this.modalService.showSharedLinkModalModal();
  }

  onUpdateLinkClicked(link: Link) {
    this.selectedLink = link;
    this.modalService.showLinkModal();
  }

  onArchiveLinkClicked(link: Link) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: `Le lien ${link.name} apparaîtra désormais dans les archives.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.linkService.archive({
          id: link.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onUnarchiveLinkClicked(link: Link) {
    const swalOptions = this.swalService.getSwalConfirmOptions({
      text: `Le lien ${link.name} n'apparaîtra plus dans les archives.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.linkService.unarchive({
          id: link.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onSetVisibilityLinkClicked(link: Link) {
    this.selectedLink = link;
    this.modalService.showLinkVisibilityModal();
  }

  onRemoveSharedLinkClicked(link: Link) {
    if (this.selectedFolder) {
      const swalOptions = this.swalService.getSwalConfirmOptions({
        text: `Le lien ${link.name} sera enlevé pour ce dossier uniquement, il ne sera pas supprimé pour tous les autres.`
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          this.linkService.removeShared({
            id: link.id,
            list: {
              categoryRoute: this.category.route,
              subcategoryRoute: this.subcategory ? this.subcategory.route : null
            }
          }, this.selectedFolder.id);
        }
      });
    }
  }

  onDeleteLinkClicked(link: Link) {
    const swalOptions = this.swalService.getSwalDeleteOptions({
      text: `Le lien ${link.name} sera supprimé de manière irréversible.`
    });
    Swal.fire(swalOptions).then((result) => {
      if (result.value) {
        this.linkService.delete({
          id: link.id,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    });
  }

  onUpdateFavoriteStatus(link: Link) {
    if (link.isFavorite) {
      this.linkService.unmarkAsFavorite({
        id: link.id,
        list: {
          categoryRoute: this.category ? this.category.route : null,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      });
    } else {
      this.linkService.markAsFavorite({
        id: link.id,
        list: {
          categoryRoute: this.category ? this.category.route : null,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      });
    }
  }

}
