import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthenticationService } from '@services/utilities/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {

	constructor(
		private authService: AuthenticationService,
		private router: Router
	) { }

	canActivate() {
		console.log("authguard");
		if (!this.authService.isTokenExpired()) {
			return true;
		}
		// not logged in so redirect to login page
		this.router.navigate(['/login']);
		return false;
	}
}
