import { HttpClient } from '@angular/common/http';
import { Component, OnInit, OnDestroy, HostBinding, ElementRef, Input, HostListener, ViewChild } from '@angular/core';
import { environment } from '@environments/environment';
import { fadeAnimation } from 'app/@pages/animations/fade-animations';
import { pagesToggleService } from 'app/@pages/services/toggler.service';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { noop } from 'jquery';
import { switchMap, tap, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { SearchService } from '@services/search.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { Subcategory } from '@models/subcategory.model';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';

@Component({
  selector: 'app-global-search-overlay',
  templateUrl: './global-search-overlay.component.html',
  animations: [fadeAnimation],
  styleUrls: ['./global-search-overlay.component.scss']
})
export class GlobalSearchOverlayComponent implements OnDestroy {
  @ViewChild('searchField') searchField: ElementRef;
  toggleSubscription: Subscription;
  _isEnabled: boolean = false;
  isVisible: boolean = false;
  value: string = '';
  modal: any;
  search: string;
  errorMessage: string;
  search$: Subscription;
  items: any;

  constructor(
    private el: ElementRef,
    private toggler: pagesToggleService,
    private http: HttpClient,
    private router: Router,
    private searchService: SearchService,
    private localStorageService: LocalStorageService
  ) {
    this.toggleSubscription = this.toggler.searchToggle.subscribe(toggleValue => {
      this.open();
    });
    this.search$ = this.searchService.search$.subscribe(data => {
      if (data) {
        this.items = data;
      }
    });
  }
  ngOnDestroy() {
    this.toggleSubscription.unsubscribe();
  }

  @Input() set isEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }
  get isEnabled() {
    return this._isEnabled;
  }


  close($event?) {
    if ($event) {
      $event.preventDefault();
    }
    this.isVisible = false;
    this.value = '';
  }

  open() {
    this.isVisible = true;
    this.value = '';
    setTimeout(() => {
      this.searchField.nativeElement.focus();
    }, 200);
  }

  @HostListener('document:keypress', ['$event']) onKeydownHandler(e) {
    var nodeName = e.target.nodeName;
    // Ignore When focus on input, textarea & contenteditable
    if (nodeName === 'INPUT' || nodeName === 'TEXTAREA' || e.target.contentEditable === 'true') {
      return;
    }
    // Ignore Escape
    if (this.isVisible && e.keyCode === 27) {
      this.isVisible = false;
      this.value = '';
    }
    // Ignore Keyes
    if (e.which !== 0 && e.charCode !== 0 && !e.ctrlKey && !e.metaKey && !e.altKey && e.keyCode !== 27) {
      this.isVisible = true;
      if (!this.value) {
        this.value = String.fromCharCode(e.keyCode | e.charCode)
      }
      this.searchField.nativeElement.focus();
    }
  }

  searchKeyPress(event) {
    if (this.isVisible && event.keyCode === 27) {
      this.isVisible = false;
    }
    this.searchService.search(this.value);
  }

  fadeInComplete() {
  }

  onSelectRoute(categoryRoute: string, subcategoryRoute?: string) {
    this.close();
    if (subcategoryRoute) {
      this.router.navigateByUrl(`documentation/${categoryRoute}/${subcategoryRoute}`);
    } else {
      this.router.navigateByUrl(`documentation/${categoryRoute}`);
    }
  }

  onSelectFolder(folder: Folder) {
    this.close();
    this.localStorageService.setSearchFolderRouteItem(folder.route);
    const currentUrl = this.router.url.substring(1, this.router.url.length);
    let newUrl = '';
    if (folder.subcategory != null) {
      newUrl = `documentation/${folder.category.route}/${folder.subcategory.route}`;
    } else {
      newUrl = `documentation/${folder.category.route}`;
    }
    if (currentUrl === newUrl) {
      window.location.reload();
    } else {
      this.router.navigateByUrl(newUrl);
    }
  }
}
