import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Subcategory } from '@models/subcategory.model';
import { LocalStorageService } from '@services/utilities/local-storage.service';

@Component({
  selector: 'app-documentation-breadcrumb',
  templateUrl: './documentation-breadcrumb.component.html',
  styleUrls: ['./documentation-breadcrumb.component.scss']
})
export class DocumentationBreadcrumbComponent implements OnInit {

  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @Input() selectedFolder: Folder;
  public isArchived: boolean;

  constructor(private localStorageService: LocalStorageService) { }

  ngOnInit() {
    this.isArchived = this.localStorageService.isArchived();
  }

  onArchiveModeChange(value: boolean) {
    this.localStorageService.setArchived(value);
  }

  getCategoryBreadcrumb() {
    const suffix = this.subcategory ? this.subcategory.name + ' > ' : '';
    return `${this.category.name} > ${suffix}`;
  }

  getFolderBreadcrumb() {
    return this.selectedFolder ? this.selectedFolder.name : '';
  }

}
