<!-- WRAP LAYOUT IF BOXED -->
<div
  class="container"
  *ngIf="_boxed; else basicLayoutBlock">
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</div>
<ng-template #basicLayoutBlock>
  <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
</ng-template>
<!-- YOUR LAYOUT CONTENT GOES INSIDE HERE -->
<ng-template #contentTpl>
  <!-- PAGE SIDEBAR -->
  <pg-sidebar>
    <ng-template #sideBarHeader>
      <img
        src="assets/img/biblio_nrjdiags_logo_white.png"
        alt="logo"
        class="brand"
        pgRetina
        src1x="assets/img/biblio_thermique_logo_white.png"
        src2x="assets/img/biblio_thermique_logo_white.png"
        width="110"
        height="35">
      <div class="sidebar-header-controls">
        <button
          type="button"
          class="btn btn-icon-link invert d-lg-inline-block d-xlg-inline-block d-md-inline-block d-sm-none d-none m-l-20 m-r-10"
          data-toggle-pin="sidebar"
          (click)="toggleMenuPin()">
          <i class="pg-icon"></i>
        </button>
      </div>
    </ng-template>
    <ng-template #menuItems>
      <ng-container *ngIf="localStorageService.isEditMode()">
        <div class="d-flex justify-content-center mt-1">
          <label
            class="btn btn-circle"
            title="Ajouter une catégorie"
            (click)="onAddCategoryClicked()">
            <i class="fas fa-plus"></i>
          </label>
          <label
            class="btn btn-circle ml-1"
            title="Éditer les catégories"
            (click)="onEditCategoryClicked()">
            <i class="fas fa-pen"></i>
          </label>
          <label
            class="btn btn-circle ml-1"
            title="Réorganiser les catégories"
            (click)="onReorderCategoriesClicked()">
            <i class="fas fa-list"></i>
          </label>
        </div>
      </ng-container>
      <pg-menu-items [Items]="menuLinks"></pg-menu-items>
    </ng-template>
  </pg-sidebar>
  <!-- PAGE CONTAINER -->
  <page-container>
    <pg-header [boxed]="_boxed">
      <!-- START MOBILE SIDEBAR TOGGLE -->
      <a
        href="javascript:void(0);"
        class="btn-icon-link toggle-sidebar d-lg-none"
        (click)="toggleMobileSidebar()">
        <i class="pg-icon">menu</i>
      </a>
      <!-- END MOBILE SIDEBAR TOGGLE -->
      <div class>
        <!-- START EMAIL MOBILE TOGGLE -->
        <a
          href="javascript:void(0);"
          class="toggle-secondary-sidebar align-items-center"
          (click)="toggleSecondarySideBar()"
          *ngIf="_layoutOption === 'email'">
          <span class="d-flex align-items-center">
            Inbox
            <span class="text-info">(12)</span>
            <span class="pg-icon">drop_down</span>
          </span>
        </a>
        <!-- END EMAIL MOBILE TOGGLE -->
        <div
          class="brand inline"
          [class.d-none]="_layoutOption === 'email'">
          <img
            src="assets/img/biblio_nrjdiags_logo.png"
            alt="logo"
            pgRetina
            src1x="assets/img/biblio_thermique_logo.png"
            src2x="assets/img/biblio_thermique_logo.png"
            width="110"
            height="35">
        </div>
        <a
          href="javascript:void(0)"
          class="search-link d-lg-inline-block d-none"
          (click)="openSearch($event)">
          <i class="fa fa-search"></i>
          <span class="bold">Rechercher</span>
        </a>
      </div>
      <div class="d-flex align-items-center">
        <ng-container *ngIf="isAdmin">
          <div class="pull-right d-lg-block d-none">
            <nz-switch
              [ngModel]="isEditMode"
              nzCheckedChildren="ÉDITION"
              nzUnCheckedChildren="CONSULTATION"
              (ngModelChange)="onSwitchModeChanged($event)">
            </nz-switch>
          </div>
          <div class="header-vertical-separator"></div>
        </ng-container>
        <!-- START User Info -->
        <div class="pull-right d-lg-block d-none">
          <ng-container *ngFor="let marketType of selectedMarketsTypes; let i = index">
            <span
              class="thumbnail-wrapper d32 circular inline"
              [ngClass]="{'ml-1': i > 0}"
              [title]="marketType.name"
              (click)="onSelectMarketTypeClicked()">
              <div class="d32 circle circular-lightgrey">
                <i class="fas tll-18 {{marketType.tllIcon}}"></i>
              </div>
            </span>
          </ng-container>
        </div>
        <div class="header-vertical-separator"></div>
        <div
          class="dropdown pull-right d-lg-block d-none"
          dropdown>
          <button
            class="profile-dropdown-toggle"
            type="button"
            dropdownToggle
            id="profileDropdown"
            aria-label="profile dropdown">
            <span class="thumbnail-wrapper d32 circular inline">
              <div
                class="d32 circle"
                style="background-color: lightgrey;">
                <span>
                  <b>{{ this.userInfos.initials}}</b>
                </span>
              </div>
            </span>
            <span
              class="bubble"
              *ngIf="notifications && notifications.unreadCount> 0">
              {{ notifications.unreadCount < 10 ? notifications.unreadCount : '9+' }}
            </span>
          </button>
          <div
            class="dropdown-menu dropdown-menu-right profile-dropdown"
            *dropdownMenu
            role="menu">
            <a
              class="dropdown-item"
              (click)="onShowNotificationsModalClicked()">
              Notifications
              <span
                class="badge badge-danger float-right"
                *ngIf="notifications && notifications.unreadCount > 0">
                {{ notifications.unreadCount }}
              </span>
            </a>
            <a
              class="dropdown-item"
              routerLink="/prestations">
              Prestations proposées
            </a>
            <a
              *ngIf="isAdmin"
              class="dropdown-item"
              routerLink="/historique">
              Historique
            </a>
            <div class="dropdown-divider"></div>
            <a
              href="#"
              class="dropdown-item"
              (click)="logout()">
              Se déconnecter
            </a>
          </div>
        </div>
        <!-- END User Info -->
      </div>
    </pg-header>
    <div class="page-content-wrapper {{_pageContainerClass}}">
      <!-- START PAGE CONTENT -->
      <div class="content {{_contentClass}}">
        <router-outlet></router-outlet>
      </div>
      <!-- END PAGE CONTENT -->
    </div>
  </page-container>
  <!-- QUICKSEARCH -->
  <app-global-search-overlay></app-global-search-overlay>
</ng-template>
<app-modal-category
  [isEdit]="isEditCategory"
  [categories]="categories">
</app-modal-category>
<app-modal-reorder-categories [categories]="categories"></app-modal-reorder-categories>
<app-modal-market-type></app-modal-market-type>
<app-modal-notifications [notifications]="notifications"></app-modal-notifications>
