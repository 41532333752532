<ng-container [ngSwitch]="IconType">
  <svg
    class="feather"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    *ngSwitchCase="'fi'">
    <use attr.xlink:href="assets/fonts/feather/feather-sprite.svg#{{IconName}}"></use>
  </svg>
  <ng-container *ngSwitchCase="'letter'">{{IconName}}</ng-container>
  <i
    *ngSwitchCase="'fas'"
    class="{{IconName}}"></i>
  <i
    *ngSwitchCase="'fa'"
    class="fa fa-{{IconName}}"></i>
  <i
    *ngSwitchDefault
    class="{{IconType}}-icon">
    {{IconName}}
  </i>
</ng-container>
