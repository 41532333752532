<div
  bsModal
  #modalNotifications="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4
          id="dialog-sizes-name1"
          class="modal-title pull-left">
          Notifications
          <span
            class="badge badge-danger ml-2"
            style="position: absolute;"
            *ngIf="notifications && notifications.unreadCount > 0">
            {{ this.notifications.unreadCount }}
          </span>
        </h4>
        <button
          type="button"
          class="close pull-right"
          (click)="closeModal()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body mt-4"
        *ngIf="notifications && notifications.unreadCount > 0;else zeroNotifications">
        <ng-container *ngIf="notifications.documents.length > 0">
          <span>
            Un nouveau document a été ajouté dans le(s) dossier(s) suivant(s) :
          </span>
          <div class="mt-2">
            <ng-container *ngFor="let item of notifications.documents">
              <ng-container
                [ngTemplateOutlet]="notifItemTemplate"
                [ngTemplateOutletContext]="{text: item.text, folder: item.folder}">
              </ng-container>
            </ng-container>
          </div>
        </ng-container>
        <div
          [ngClass]="{'mt-5': notifications.documents.length > 0}"
          *ngIf="notifications.links.length > 0">
          <span>
            Un nouveau lien a été ajouté dans le(s) dossier(s) suivant(s) :
          </span>
          <div class="mt-2">
            <ng-container *ngFor="let item of notifications.links let i = index">
              <ng-container *ngFor="let folder of item.folders let j = index">
                <ng-container
                  [ngTemplateOutlet]="notifItemTemplate"
                  [ngTemplateOutletContext]="{text: item.text, folder: folder}">
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
      <div
        class="modal-footer"
        *ngIf="notifications && notifications.unreadCount > 0;">
        <div class="d-flex w-100">
          <button class="btn btn-primary btn-lg btn-icon-left mr-auto">
            <i class="fas fa-times"></i>
            <span>Plus tard</span>
          </button>
          <button
            class="btn btn-warning btn-lg btn-icon-right ml-auto"
            (click)="markAsReadAll()">
            <span>Tout marquer comme lu</span>
            <i class="fas fa-eye"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template
  #notifItemTemplate
  let-text="text"
  let-folder="folder">
  <div
    class="notif-item my-1 text-selection-none"
    (click)="onSelectFolder(folder)">
    <div class="d-flex justify-content-center">
      <span
        class="span-large span-overflow"
        [title]="text">
        {{text}}
      </span>
    </div>
    <div class="d-flex justify-content-center">
      <span
        class="span-overflow span-opacity-60"
        [title]="folder.hierarchy">
        {{ folder.hierarchy }}
      </span>
    </div>
  </div>
</ng-template>
<ng-template #zeroNotifications>
  <div class="modal-body mt-4">
    <span>Aucune nouvelle notification</span>
  </div>
</ng-template>
