<div class="timeline-container">
  <section
    class="timeline"
    *ngFor="let audit of audits; let i = index">
    <div class="timeline-block">
      <div class="timeline-point {{ audit.iconClass }}">
        <i class="{{ audit.icon }}"></i>
      </div>
      <div class="timeline-content">
        <div class="card social-card share full-width">
          <div class="card-header clearfix">
            <h5>{{ audit.title }}</h5>
            <h6 class="mt-1">{{ audit.user.fullName }}</h6>
          </div>
          <div
            class="card-description"
            [innerHTML]="audit.content">
          </div>
        </div>
        <div class="event-date">
          <small class="fs-12 hint-text">{{ audit.dateFormatted }}</small>
        </div>
      </div>
    </div>
  </section>
</div>
