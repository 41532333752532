import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { MarketType } from '@models/market-type.model';
import { Subcategory } from '@models/subcategory.model';
import { DocumentService } from '@services/document.service';
import { FolderService } from '@services/folder.service';
import { ModalService } from '@services/utilities/modal.service';
import { NotifService } from '@services/utilities/notif.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-import-documents',
  templateUrl: './modal-import-documents.component.html',
  styleUrls: ['./modal-import-documents.component.scss']
})
export class ModalImportDocumentsComponent implements AfterViewInit, OnDestroy {
  files: File[] = [];
  @ViewChild('modalImportDocuments', { static: false }) childModal: ModalDirective;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @Input() folder: Folder;
  private uploadProcess$: Subscription;

  constructor(
    private modalService: ModalService,
    private notifService: NotifService,
    private documentService: DocumentService,
    private folderService: FolderService

  ) { }

  ngAfterViewInit() {
    this.modalService.setImportDocumentsModal(this.childModal);
  }

  ngOnDestroy() {
    if (this.uploadProcess$) {
      this.uploadProcess$.unsubscribe();
    }
  }

  onImport(event) {
    for (let i = 0; i < event.addedFiles.length; i++) {
      this.uploadProcess$ = this.documentService.import(this.folder.id, event.addedFiles[i]).subscribe(
        (response) => {
          this.files.push(event.addedFiles[i]);
          this.notifService.showNotif({
            type: 'success',
            text: response.message
          });
          if (this.subcategory) {
            this.folderService.getForList(this.category.route, this.subcategory.route);
          } else {
            this.folderService.getForList(this.category.route);
          }
        },
        (error) => {
          this.notifService.showNotif({
            type: 'danger',
            text: "Une erreur est survenue l'import du document"
          });
        }
      )

    }
  }

  onModalShown() {
  }

  onModalHidden() {
  }

  closeModal() {
    this.files = [];
    this.childModal.hide();
  }
}
