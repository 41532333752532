import { Component, Input, OnInit } from '@angular/core';
import { Audit } from '@models/audit.model';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() audits: Audit[];

  constructor() { }

  ngOnInit() {
  }

}
