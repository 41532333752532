<div
  class="overlay"
  [class.hide]=!isVisible
  [@fadeAnimation]="isVisible">
  <div class="overlay-content has-results m-t-20">
    <div class="container-fluid">
      <img
        alt="logo"
        class="overlay-brand"
        pgRetina
        src1x="../../assets/img/biblio_thermique_logo.png"
        src2x="../../assets/img/biblio_thermique_logo.png"
        height="35"
        src="../../assets/img/biblio_thermique_logo.png"
        width="110">
      <a
        class="close-icon-light btn-link btn-rounded  overlay-close text-black"
        href="javascript:void(0)"
        (click)="close($event)">
        <i class="pg-icon">close</i>
      </a>
    </div>
    <div class="container-fluid">
      <input
        autocomplete="off"
        #searchField
        (keyup)="searchKeyPress($event)"
        class="no-border overlay-search bg-transparent"
        [(ngModel)]="value"
        placeholder="Rechercher..."
        spellcheck="false">
    </div>
    <div
      class="container-fluid"
      *ngIf="value.length > 0 && items">
      <div class="search-results m-t-40">
        <div class="row">
          <div class="col-3">
            <h4 class="bold">Catégories & sous-catégories</h4>
            <div *ngIf="(items.categories && items.categories.length > 0) || (items.subcategories && items.subcategories.length > 0);else noItemsFound">
              <div
                class="searchItem mx-auto"
                *ngFor="let categoryItem of items.categories"
                (click)="onSelectRoute(categoryItem.searchable.route)">
                <div class="text-center">
                  <h6>
                    <i class="{{categoryItem.searchable.icon}}"></i>
                    {{ categoryItem.title }}
                  </h6>
                  <p class="created-date">Ajoutée le {{ categoryItem.created }}</p>
                </div>
              </div>
              <div
                class="searchItem mx-auto text-selection-none"
                *ngFor="let subcategoryItem of items.subcategories"
                (click)="onSelectRoute(subcategoryItem.searchable.category.route, subcategoryItem.searchable.route)">
                <div class="text-center">
                  <h6>{{ subcategoryItem.title }}</h6>
                  <p class="created-date">
                    Ajoutée le {{ subcategoryItem.created }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-6"
            style="overflow-y: auto;">
            <h4 class="bold">Dossiers</h4>
            <div *ngIf="items.folders && items.folders.length > 0;else noItemsFound">
              <div
                class="searchItem mx-auto text-selection-none"
                *ngFor="let folderItem of items.folders"
                (click)="onSelectFolder(folderItem.searchable)">
                <div class="text-center">
                  <h6>{{ folderItem.title }}</h6>
                  <p class="created-date">Ajouté le {{ folderItem.created }}</p>
                </div>
              </div>
            </div>
            <h4 class="bold">Documents</h4>
            <div *ngIf="items.documents && items.documents.length > 0;else noItemsFound">
              <div
                class="searchItem mx-auto text-selection-none"
                *ngFor="let documentItem of items.documents"
                (click)="onSelectFolder(documentItem.searchable.folder)">
                <div class="text-center">
                  <h6 *ngIf="documentItem.searchable.folder.subcategory; else documentFolderWithoutSubcategory">
                    {{ documentItem.searchable.folder.category.name }} > {{ documentItem.searchable.folder.subcategory.name }} > {{ documentItem.searchable.folder.name }} >
                    <b>{{ documentItem.title }}</b>
                  </h6>
                  <ng-template #documentFolderWithoutSubcategory>
                    {{ documentItem.searchable.folder.category.name }} > {{ documentItem.searchable.folder.name }} >
                    <b>{{ documentItem.title }}</b>
                  </ng-template>
                  <p class="created-date">Ajouté le {{ documentItem.created }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-3"
            style="overflow-y: auto;">
            <h4 class="bold">Liens</h4>
            <div *ngIf="items.links && items.links.length > 0;else noItemsFound">
              <div *ngFor="let linkItem of items.links">
                <div
                  class="searchItem mx-auto text-selection-none"
                  *ngFor="let folder of linkItem.searchable.folders"
                  (click)="onSelectFolder(folder)">
                  <div class="text-center">
                    <h6 *ngIf="folder.subcategory; else linkFolderWithoutSubcategory">
                      {{ folder.category.name }} > {{ folder.subcategory.name }} > {{ folder.name }} >
                      <b>{{ linkItem.title }}</b>
                    </h6>
                    <ng-template #linkFolderWithoutSubcategory>
                      {{ folder.category.name }} > {{ folder.name }} >
                      <b>{{ linkItem.title }}</b>
                    </ng-template>
                    <p class="created-date">Ajouté le {{ linkItem.created }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #noItemsFound>Aucun élément trouvé</ng-template>
