import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { faSync } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Link } from '@models/link.model';
import { MarketType } from '@models/market-type.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { LinkService } from '@services/link.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-visibility-link',
  templateUrl: './modal-visibility-link.component.html',
  styleUrls: ['./modal-visibility-link.component.scss']
})
export class ModalVisibilityLinkComponent implements OnInit, AfterViewInit {

  @Input() link: Link;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @ViewChild('modalVisibilityLink', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;
  public selectedMarketsTypesIds: number[] = [];

  constructor(
    private linkService: LinkService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setLinkVisibilityModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Mettre à jour',
      objectName: 'le lien',
      icon: faSync
    });
    this.linkService.setVisibilitySubmitButton(this.submitButton);
    this.selectedMarketsTypesIds = this.link.marketsTypes.map((i) => { return i.id });
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  onSelectedMarketsTypesChange(marketsTypes: MarketType[]) {
    this.selectedMarketsTypesIds = marketsTypes.map((i) => { return i.id });;
  }

  onUpdateVisibilityClicked() {
    if (this.isValidButton()) {
      this.linkService.setVisibility({
        id: this.link.id,
        list: {
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      }, this.selectedMarketsTypesIds);
    }
  }

  isValidButton() {
    return this.selectedMarketsTypesIds && this.selectedMarketsTypesIds.length > 0;
  }

}
