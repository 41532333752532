import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class SearchService {

    private route = '/categories/search';
    public search$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder,
    ) { }


    search(search: string): void {
        this.apiService.get(`${this.route}?search=` + search).subscribe(
            (data: any) => {
                this.search$.next(data);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

}
