import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class UserNotificationService {

    private route = '/notifications';
    public notifications$ = new BehaviorSubject<User[]>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService
    ) { }

    getUnreadNotifications(): void {
        this.apiService.get(`${this.route}/unread`)
            .subscribe(
                (notifications: any) => {
                    this.notifications$.next(notifications);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    markAsReadAll(): void {
        this.apiService.get(`${this.route}/mark-as-read-all`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.getUnreadNotifications();
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

}
