import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Link } from '@models/link.model';
import { MarketType } from '@models/market-type.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { LinkService } from '@services/link.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-link',
  templateUrl: './modal-link.component.html',
  styleUrls: ['./modal-link.component.scss']
})
export class ModalLinkComponent implements OnInit, AfterViewInit {

  @Input() selectedFolder: Folder;
  @Input() link: Link;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @ViewChild('modalLink', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private linkService: LinkService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setLinkModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.determinateSubmitButton(this.link, 'le lien');
    this.linkService.setSubmitButton(this.submitButton);
    this.form = this.linkService.getForm(this.selectedFolder.id, this.link);
  }

  onModalHidden() {
    this.form = null;
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      if (this.link) {
        this.linkService.update({
          id: this.link.id,
          form: this.form,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      } else {
        this.form.patchValue({
          'selectedMarketsTypes': JSON.stringify(this.localStorageService.getSelectedMarketsTypesIds())
        });
        this.linkService.store({
          form: this.form,
          list: {
            categoryRoute: this.category.route,
            subcategoryRoute: this.subcategory ? this.subcategory.route : null
          }
        });
      }
    }
  }

  isValidForm() {
    return this.form.valid;
  }

  getFavicon() {
    if (this.form.controls['url'] && this.form.controls['url'].value) {
      return 'http://www.google.com/s2/favicons?domain=' + this.form.controls['url'].value;
    }
    return '';
  }

}
