import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Link } from '@models/link.model';
import { FavoriteService } from '@services/utilities/favorite.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import * as Noty from 'noty';


@Component({
  selector: 'clickable-link',
  templateUrl: './clickable-link.component.html',
  styleUrls: ['./clickable-link.component.scss']
})
export class ClickableLinkComponent implements OnInit {

  @Input() index: number;
  @Input() currentHoverIndex: number;
  @Input() link: Link;
  @Output() updateButtonClicked = new EventEmitter();
  @Output() archiveButtonClicked = new EventEmitter();
  @Output() unarchiveButtonClicked = new EventEmitter();
  @Output() visibilityButtonClicked = new EventEmitter();
  @Output() removeSharedButtonClicked = new EventEmitter();
  @Output() deleteButtonClicked = new EventEmitter();
  @Output() favoriteButtonClicked = new EventEmitter();
  @Output() mouseOverLinks = new EventEmitter();

  constructor(
    public favService: FavoriteService,
    public localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
  }

  onMouseOverLinks(index: number) {
    this.mouseOverLinks.emit(index);
  }

  onUpdateClicked(event: any) {
    event.stopPropagation();
    this.updateButtonClicked.emit(this.link);
  }

  onArchiveClicked(event: any) {
    event.stopPropagation();
    this.archiveButtonClicked.emit(this.link);
  }

  onUnarchiveClicked(event: any) {
    event.stopPropagation();
    this.unarchiveButtonClicked.emit(this.link);
  }

  onSetVisibilityClicked(event: any) {
    event.stopPropagation();
    this.visibilityButtonClicked.emit(this.link);
  }

  onRemoveSharedClicked(event: any) {
    event.stopPropagation();
    this.removeSharedButtonClicked.emit(this.link);
  }

  onDeleteClicked(event: any) {
    event.stopPropagation();
    this.deleteButtonClicked.emit(this.link);
  }

  onTextCopied(text: string) {
    navigator.clipboard.writeText(text);
    new Noty({
      type: 'success',
      layout: 'bottomRight',
      theme: 'bootstrap-v4',
      text: text + ' copié !',
      timeout: 2000
    }).show();
  }

  onUpdateFavoriteStatus(event: any) {
    event.stopPropagation();
    this.favoriteButtonClicked.emit(this.link);
  }

  hideSharedIcon() {
    if (this.link && !this.link.isShared) {
      return true;
    }
    if (this.localStorageService.isEditMode() && this.link && !this.link.isFavorite) {
      return this.index == this.currentHoverIndex;
    }
    return false;
  }

}
