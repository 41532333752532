import { Component, OnInit } from '@angular/core';
import { Document } from '@models/document.model';
import { Link } from '@models/link.model';
import { DocumentService } from '@services/document.service';
import { LinkService } from '@services/link.service';
import { FavoriteService } from '@services/utilities/favorite.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  public favorites$: Subscription;
  public documents = [];
  public links = [];
  public currentHoverDocumentIndex = -1;
  public currentHoverLinkIndex = -1;

  constructor(
    private documentService: DocumentService,
    private linkService: LinkService,
    private favoriteService: FavoriteService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.favoriteService.getFavorites();
  }

  onDocumentUnmarkAsFavorite(document: Document) {
    this.documentService.unmarkAsFavorite({ id: document.id });
  }

  onLinkUnmarkAsFavorite(link: Link) {
    this.linkService.unmarkAsFavorite({ id: link.id });
  }

  private initSubscriptions() {
    this.favorites$ = this.favoriteService.favorites$.subscribe(
      (data) => {
        if (data) {
          this.documents = data.documents;
          this.links = data.links;
        }
      }
    );
  }

}
