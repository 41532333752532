<div class="row justify-content-md-center p-l-50 m-l-20 p-r-50 m-r-20 p-t-50 sm-p-l-15 sm-p-r-15 sm-p-t-40">
  <div class="col-4 login-container bg-white">
    <img
      src="../../assets/img/biblio_nrjdiags_logo_login.png"
      class="img-login">
    <form
      class="p-t-15"
      role="form"
      [formGroup]="form"
      (ngSubmit)="onLogIn()">
      <div
        class="form-group form-group-default required "
        pgFormGroupDefault>
        <label>Email</label>
        <input
          type="text"
          id="email"
          name="email"
          class="form-control"
          formControlName="email"
          required>
      </div>
      <div
        class="form-group form-group-default required "
        pgFormGroupDefault>
        <label>Mot de passe</label>
        <input
          type="password"
          id="password"
          name="password"
          class="form-control"
          formControlName="password"
          required>
      </div>
      <div class="row justify-content-md-center mt-4 mb-3">
        <button
          class="btn btn-primary btn-lg btn-cons"
          type="submit">
          Se connecter
        </button>
      </div>
    </form>
  </div>
</div>
