import Swal from 'sweetalert2';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SubmitButton } from '@models/submit-button.model';
import { CategoryService } from '@services/category.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubcategoryService } from '@services/subcategory.service';
import { Subcategory } from '@models/subcategory.model';
import { SwalService } from '@services/utilities/swal.service';
import { Category } from '@models/category.model';

@Component({
  selector: 'app-modal-category',
  templateUrl: './modal-category.component.html',
  styleUrls: ['./modal-category.component.scss']
})
export class ModalCategoryComponent implements OnInit, AfterViewInit {

  @Input() isEdit: boolean;
  @Input() categories: Category[];
  @ViewChild('modalCategory', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;
  public selectedCategory: Category = null;
  public subcategories: Subcategory[] = [];
  public isLinkDivVisible = false;

  constructor(
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private modalService: ModalService,
    private swalService: SwalService,
    private submitButtonService: SubmitButtonService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setCategoryModal(this.childModal);
  }

  onModalShown() {
    this.selectedCategory = (this.isEdit && this.categories.length > 0) ? this.categories[0] : null;
    this.submitButton = this.submitButtonService.determinateSubmitButton(this.selectedCategory, 'la catégorie');
    this.categoryService.setCrudSubmitButton(this.submitButton);
    if (this.selectedCategory) {
      this.subcategories = this.selectedCategory.subcategories;
    }
    this.form = this.categoryService.getForm(this.selectedCategory);
  }

  onModalHidden() {
    this.form = null;
    this.subcategories = [];
  }

  closeModal() {
    this.childModal.hide();
  }

  onCategorySelected(cat: Category) {
    this.selectedCategory = cat;
    this.subcategories = this.selectedCategory.subcategories;
    this.form = this.categoryService.getForm(this.selectedCategory);
  }

  onDeleteClicked() {
    if (this.selectedCategory) {
      const swalOptions = this.swalService.getSwalDeleteOptions({
        text: `La catégorie ${this.selectedCategory.name} sera supprimée de manière irréversible, ainsi que tous ses documents, liens et archives associés.`
      });
      Swal.fire(swalOptions).then((result) => {
        if (result.value) {
          this.categoryService.delete(this.selectedCategory.id);
          this.form = this.categoryService.getForm();
        }
      });
    }
  }

  onAddSubcategoryClicked() {
    this.subcategories.push({
      id: -1,
      rowId: '_' + Math.random().toString(36).substr(2, 9),
      categoryId: 0,
      name: '',
      abbreviation: '',
      route: '',
      order: 0
    });
  }

  deleteRowItem(rowId: number | string) {
    for (let i = 0; i < this.subcategories.length; i++) {
      if (this.subcategories[i].rowId === rowId) {
        console.log(rowId);
        this.subcategories.splice(i, 1);
        break;
      }
    }
  }

  submitForm() {
    if (this.isValidForm()) {
      this.addSubcategoriesToForm();
      if (this.isEdit) {
        if (this.selectedCategory) {
          this.categoryService.update(this.selectedCategory.id, this.form);
        }
      } else {
        this.categoryService.store(this.form);
      }
    }
  }

  isValidForm() {
    let areValidSubcategories = true;
    for (let subcat of this.subcategories) {
      if (subcat.name.length <= 0 || subcat.abbreviation.length !== 2) {
        areValidSubcategories = false;
        break;
      }
    }
    return this.form.valid && areValidSubcategories;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.subcategories, event.previousIndex, event.currentIndex);
  }

  updateLinkDivVisibility() {
    this.isLinkDivVisible = !this.isLinkDivVisible;
  }

  private addSubcategoriesToForm() {
    let subcategories = [];
    for (let subcat of this.subcategories) {
      subcategories.push(this.subcategoryService.getForm(subcat));
    }
    this.form = this.formBuilder.group({
      ...this.form.controls,
      subcategories: new FormArray(subcategories),
    });
  }

}
