import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-app-without-sidebar',
  templateUrl: './app-without-sidebar.component.html',
  styleUrls: ['./../../app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWithoutSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
