<ng-select
    [ngClass]="class"
    [items]="items"
    [multiple]="true"
    [(ngModel)]="selectedNgModel"
    [placeholder]="placeholder">
    <ng-template ng-header-tmp>
        <div class="row">
            <div class="col">
                <button class="btn btn-sm btn-rounded btn-primary w-100" (click)="onSelectAll()">{{selectAllText}}</button>
            </div>
            <div class="col">
                <button class="btn btn-sm btn-rounded btn-primary w-100" (click)="onDeselectAll()">{{deselectAllText}}</button>
            </div>
        </div>
    </ng-template>
    <ng-template ng-multi-label-tmp let-selectedItems="items" let-clear="clear">
        <ng-container *ngIf="selectedItems.length <= maxSelectedItems">
            <div class="ng-value" *ngFor="let selectedItem of selectedItems">
                <span class="ng-value-label">{{selectedItem.label}}</span>
                <span class="ng-value-icon right" (click)="clearSelectItem(clear, selectedItem)" aria-hidden="true">×</span>
            </div>
        </ng-container>
        <div class="ng-value" *ngIf="selectedItems.length < items.length && selectedItems.length > maxSelectedItems">
            <span class="ng-value-label">{{selectedItems.length}} {{countSelectedText}}</span>
        </div>
        <div class="ng-value" *ngIf="selectedItems.length == items.length">
            <span class="ng-value-label">{{selectAllText}}</span>
        </div>
    </ng-template>
</ng-select>