import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Category } from '@models/category.model';
import { SubmitButton } from '@models/submit-button.model';
import { CategoryService } from '@services/category.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-reorder-categories',
  templateUrl: './modal-reorder-categories.component.html',
  styleUrls: ['./modal-reorder-categories.component.scss']
})
export class ModalReorderCategoriesComponent implements OnInit, AfterViewInit {

  @Input() categories: Category[];
  @ViewChild('modalReorderCategory', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;

  constructor(
    private categoryService: CategoryService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setCategoryReorderModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.determinateSubmitButton(true, 'les catégories');
    this.categoryService.setReorderSubmitButton(this.submitButton);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      this.categoryService.reorder(this.categories);
    }
  }

  isValidForm() {
    return this.categories.length > 0;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
  }

}
