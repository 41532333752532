import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Audit } from '@models/audit.model';
import { User } from '@models/user.model';
import { AuditService } from '@services/audit.service';
import { UserService } from '@services/user.service';
import { NotifService } from '@services/utilities/notif.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

  public audits: Audit[] = [];
  private audits$: Subscription;
  public eventsTypes: any[] = [];
  private eventsTypes$: Subscription;
  public eventsEntities: any[] = [];
  private eventsEntities$: Subscription;
  public users: User[] = [];
  private users$: Subscription;
  public form: FormGroup;
  private form$: Subscription;

  constructor(
    private auditService: AuditService,
    private userService: UserService,
    private notifService: NotifService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.getValues();
    this.form = this.auditService.getFiltersForm();
    this.form$ = this.form.valueChanges.subscribe(() => {
      if (this.isValidForm()) {
        this.auditService.getForList(this.form);
      } else {
        this.audits = [];
        this.displayInvalidFormReasons();
      }
    });
    this.auditService.getForList(this.form);
  }

  ngOnDestroy() {
    if (this.audits$) {
      this.audits$.unsubscribe();
    }
    if (this.eventsTypes$) {
      this.eventsTypes$.unsubscribe();
    }
    if (this.eventsEntities$) {
      this.eventsEntities$.unsubscribe();
    }
    if (this.users$) {
      this.users$.unsubscribe();
    }
    if (this.form$) {
      this.form$.unsubscribe();
    }
  }

  private initSubscriptions() {
    this.audits$ = this.auditService.audits$.subscribe(
      (audits: Audit[]) => {
        this.audits = audits;
      }
    );
    this.eventsTypes$ = this.auditService.eventsTypes$.subscribe(
      (eventsTypes: any[]) => {
        this.eventsTypes = eventsTypes;
      }
    );
    this.eventsEntities$ = this.auditService.eventsEntities$.subscribe(
      (eventsEntities: any[]) => {
        this.eventsEntities = eventsEntities;
      }
    );
    this.users$ = this.userService.users$.subscribe(
      (users: User[]) => {
        this.users = users;
      }
    );
  }

  private getValues() {
    this.auditService.getEventsTypes();
    this.auditService.getEventsEntities();
    this.userService.getForSelect();
  }

  private isValidForm() {
    const dateFrom = this.form.get('dateFrom').value;
    const dateTo = this.form.get('dateTo').value;
    return this.form.valid && dateFrom <= dateTo;
  }

  private displayInvalidFormReasons() {
    const dateFrom = this.form.get('dateFrom').value;
    const dateTo = this.form.get('dateTo').value;
    if (!(dateFrom instanceof Date)) {
      this.notifService.showErrorNotif('Une date de début est obligatoire');
    }
    if (!(dateTo instanceof Date)) {
      this.notifService.showErrorNotif('Une date de fin est obligatoire');
    }
    if (dateFrom > dateTo) {
      this.notifService.showErrorNotif('La date de début ne doit pas être plus récente que la date de fin');
    }
  }

}
