import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Link } from '@models/link.model';
import { SubmitButton } from '@models/submit-button.model';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { FolderService, GetForListFavoriteParam, GetForListParam } from './folder.service';
import { ApiService } from './utilities/api.service';
import { FavoriteService } from './utilities/favorite.service';
import { ModalService } from './utilities/modal.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';

export interface LinkStoreParam {
    form: FormGroup;
    list: GetForListParam;
}

export interface LinkUpdateParam {
    id: number;
    form: FormGroup;
    list: GetForListParam;
}

export interface LinkIdParam {
    id: number;
    list: GetForListParam;
}

export interface LinkFavoriteParam {
    id: number;
    list?: GetForListFavoriteParam;
}

@Injectable()
export class LinkService {

    private route = '/links';
    private submitButton: SubmitButton;
    private visibilitySubmitButton: SubmitButton;
    private sharedSubmitButton: SubmitButton;
    public links$ = new BehaviorSubject<Link[]>(undefined);

    constructor(
        private folderService: FolderService,
        private favoriteService: FavoriteService,
        private apiService: ApiService,
        private notifService: NotifService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private submitButtonService: SubmitButtonService
    ) { }

    setSubmitButton(button: SubmitButton): void {
        this.submitButton = button;
    }

    setVisibilitySubmitButton(button: SubmitButton): void {
        this.visibilitySubmitButton = button;
    }

    setSharedSubmitButton(button: SubmitButton): void {
        this.sharedSubmitButton = button;
    }

    getForm(folderId: number, link?: Link): FormGroup {
        const name = link ? link.name : '';
        const url = link ? link.url : '';
        const login = link ? link.login : '';
        const password = link ? link.password : '';
        return this.formBuilder.group({
            folderId: [folderId, Validators.required],
            name: [name, Validators.required],
            url: [url, Validators.required],
            login: login,
            password: password,
            selectedMarketsTypes: []
        });
    }

    getAll(): void {
        this.apiService.get(this.route).subscribe(
            (links: Link[]) => {
                this.links$.next(links);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    store(param: LinkStoreParam): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.post(this.route, param.form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideLinkModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    update(param: LinkUpdateParam): void {
        this.submitButtonService.setDisabled(this.submitButton);
        this.apiService.put(this.route + '/' + param.id, param.form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.submitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideLinkModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    archive(param: LinkIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/archive`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    unarchive(param: LinkIdParam): void {
        this.apiService.get(`${this.route}/${param.id}/unarchive`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    delete(param: LinkIdParam): void {
        this.apiService.delete(this.route + '/' + param.id)
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    markAsFavorite(param: LinkFavoriteParam): void {
        this.apiService.get(`${this.route}/${param.id}/mark-as-favorite`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                if (param.list.categoryRoute) {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                } else {
                    this.favoriteService.getFavorites();
                }
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    unmarkAsFavorite(param: LinkFavoriteParam): void {
        this.apiService.get(`${this.route}/${param.id}/unmark-as-favorite`).subscribe(
            (success) => {
                this.notifService.showSuccessNotif(success);
                if (param.list) {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                } else {
                    this.favoriteService.getFavorites();
                }
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    setVisibility(param: LinkIdParam, selectedMarketsTypes: number[]): void {
        this.submitButtonService.setDisabled(this.visibilitySubmitButton);
        this.apiService.put(`${this.route}/${param.id}/set-visibility`, {
            selectedMarketsTypes: JSON.stringify(selectedMarketsTypes)
        })
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.visibilitySubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideLinkVisibilityModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    addShared(param: LinkIdParam, folderId: number): void {
        this.submitButtonService.setDisabled(this.sharedSubmitButton);
        this.apiService.put(`${this.route}/${param.id}/add-shared`, {
            folderId: JSON.stringify(folderId)
        })
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.sharedSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.modalService.hideSharedLinkModalModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    removeShared(param: LinkIdParam, folderId: number): void {
        this.apiService.put(`${this.route}/${param.id}/remove-shared`, {
            folderId: JSON.stringify(folderId)
        })
            .subscribe(
                (success) => {
                    this.folderService.getForList(param.list.categoryRoute, param.list.subcategoryRoute);
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }
}
