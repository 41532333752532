import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { faFolder, faFolderOpen, faArchive } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { LocalStorageService } from '@services/utilities/local-storage.service';

@Component({
  selector: 'list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss']
})
export class ListViewComponent implements OnInit, OnChanges {

  @Input() isEditCategory: boolean = false;
  @Input() items: Category[] | Folder[];
  @Output() itemSelected = new EventEmitter();
  public activeRouteItem: string;

  constructor(public localStorageService: LocalStorageService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['items'] && changes['items'].previousValue != changes['items'].currentValue) {
      if (this.items && this.items.length > 0) {
        if (!this.isEditCategory) {
          this.activeRouteItem = this.localStorageService.getActiveRouteItem();
        } else {
          this.activeRouteItem = this.items[0].route;
        }
      }
    }
  }

  onItemClicked(route: string) {
    this.activeRouteItem = route;
    this.localStorageService.setActiveRouteItem(route);
    this.itemSelected.emit(this.getItem(route));
  }

  getIcon(route: string) {
    if (this.localStorageService.isArchived()) {
      return faArchive;
    }
    return this.activeRouteItem === route ? faFolderOpen : faFolder;
  }

  private getItem(route: string): Category | Folder {
    for (let item of this.items) {
      if (item.route === route) {
        return item;
      }
    }
    return null;
  }

}
