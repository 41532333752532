import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Subcategory } from '@models/subcategory.model';
import { FolderService } from '@services/folder.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';

@Component({
  selector: 'app-documentation-comment',
  templateUrl: './documentation-comment.component.html',
  styleUrls: ['./documentation-comment.component.scss']
})
export class DocumentationCommentComponent implements OnInit, OnChanges {

  @Input() selectedFolder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  public form: FormGroup;

  constructor(
    public localStorageService: LocalStorageService,
    private folderService: FolderService
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: any) {
    if (changes['selectedFolder'] && changes['selectedFolder'].previousValue != changes['selectedFolder'].currentValue) {
      this.form = this.folderService.getCommentForm(this.selectedFolder);
    }
  }

  canDisplayComment() {
    if (this.localStorageService.isArchived()) {
      return false;
    }
    return ((this.selectedFolder && this.selectedFolder.comment && this.selectedFolder.comment.length > 0) || this.localStorageService.isEditMode());
  }

  onSaveCommentClicked() {
    if (this.selectedFolder) {
      this.folderService.updateComment({
        id: this.selectedFolder.id,
        form: this.form,
        categoryRoute: this.category.route,
        subcategoryRoute: this.subcategory ? this.subcategory.route : null
      });
    }
  }

  getPComment() {
    return this.selectedFolder.comment.replace(/\r?\n|\r/g, '<br>');
  }

}
