import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Subcategory } from '@models/subcategory.model';
import { CategoryService } from '@services/category.service';
import { FolderService } from '@services/folder.service';
import { SubcategoryService } from '@services/subcategory.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent implements OnInit, OnDestroy {

  public selectedFolder: Folder = null;
  public folders: Folder[] = [];
  private folders$: Subscription;
  public category: Category = null;
  private category$: Subscription;
  public subcategory: Subcategory = null;
  private subcategory$: Subscription;
  private isSubcategoryRequired = true;

  constructor(
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private folderService: FolderService,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
    this.initValues();
  }

  ngOnDestroy() {
    this.folders$.unsubscribe();
    this.category$.unsubscribe();
    this.subcategory$.unsubscribe();
  }

  canDisplayContent() {
    const isValidCategory = (this.category != null && this.category != undefined);
    const isValidSubCategory = (this.subcategory != null && this.category != undefined);
    if (this.isSubcategoryRequired) {
      return isValidCategory && isValidSubCategory;
    }
    return isValidCategory;
  }

  onFolderSelected(folder: Folder) {
    this.selectedFolder = folder;
  }

  private initSubscriptions() {
    this.folders$ = this.folderService.folders$.subscribe(
      (folders: Folder[]) => {
        if (folders) {
          this.folders = folders;
          this.determinateSelectedFolder();
        }
      }
    );
    this.category$ = this.categoryService.category$.subscribe(
      (category: Category) => {
        this.category = category;
      }
    );
    this.subcategory$ = this.subcategoryService.subcategory$.subscribe(
      (subcategory: Subcategory) => {
        this.subcategory = subcategory;
      }
    );
  }

  private initValues() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.category = null;
      this.subcategory = null;
      const categoryRoute = params.get('cat');
      const subcategoryRoute = params.get('subcat');
      this.categoryService.getByRoute(categoryRoute);
      if (subcategoryRoute) {
        this.subcategoryService.getByRoute(subcategoryRoute);
      } else {
        this.isSubcategoryRequired = false;
      }
      this.folderService.getForList(categoryRoute, subcategoryRoute);
    });
  }

  private determinateSelectedFolder() {
    if (this.folders && this.folders.length > 0) {
      let route = this.localStorageService.getSearchFolderRouteItem();
      const folder = this.getFolder(route);
      if (folder) {
        this.selectedFolder = folder;
        this.localStorageService.resetSearchFolderRouteItem();
      } else {
        route = this.localStorageService.getActiveRouteItem();
        const folder = this.getFolder(route);
        if (folder) {
          this.selectedFolder = folder;
        } else {
          this.selectedFolder = this.folders[0];
        }
      }
      this.localStorageService.setActiveRouteItem(this.selectedFolder.route);
    } else {
      this.selectedFolder = null;
      this.localStorageService.resetActiveRouteItem();
    }
  }

  private getFolder(route: string): Folder {
    for (let f of this.folders) {
      if (f.route === route) {
        return f;
      }
    }
    return null;
  }

}
