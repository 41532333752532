import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Category } from '@models/category.model';
import { SubmitButton } from '@models/submit-button.model';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ApiService } from './utilities/api.service';
import { LocalStorageService } from './utilities/local-storage.service';
import { ModalService } from './utilities/modal.service';
import { NotifService } from './utilities/notif.service';
import { SubmitButtonService } from './utilities/submit-button.service';
import { SwalService } from './utilities/swal.service';

@Injectable()
export class CategoryService {

    private route = '/categories';
    private crudSubmitButton: SubmitButton;
    private reorderSubmitButton: SubmitButton;
    public category$ = new BehaviorSubject<Category>(undefined);
    public categories$ = new BehaviorSubject<Category[]>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private swalService: SwalService,
        private modalService: ModalService,
        private formBuilder: FormBuilder,
        private submitButtonService: SubmitButtonService,
        private localStorageService: LocalStorageService
    ) { }

    setCrudSubmitButton(button: SubmitButton): void {
        this.crudSubmitButton = button;
    }

    setReorderSubmitButton(button: SubmitButton): void {
        this.reorderSubmitButton = button;
    }

    getAll(): void {
        this.apiService.post(this.route + '/list', {
            'selectedMarketsTypes': JSON.stringify(this.localStorageService.getSelectedMarketsTypesIds())
        }).subscribe(
            (categories: Category[]) => {
                this.categories$.next(categories);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    getByRoute(route: string): void {
        this.apiService.get(`${this.route}/route/${route}`).subscribe(
            (category: Category) => {
                this.category$.next(category);
            },
            (error) => {
                this.notifService.showErrorNotif(error);
            }
        );
    }

    store(form: any): void {
        this.submitButtonService.setDisabled(this.crudSubmitButton);
        this.apiService.post(this.route, form.value)
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.crudSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.getAll();
                    this.modalService.hideCategoryModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    update(id: number, form: any): void {
        this.submitButtonService.setDisabled(this.crudSubmitButton);
        this.apiService.put(this.route + '/' + id, form.value)
            .pipe(
                catchError(error => {
                    this.swalService.showSwalError(error);
                    console.log(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.crudSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.getAll();
                    this.modalService.hideCategoryModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    delete(id: number): void {
        this.apiService.delete(this.route + '/' + id)
            .subscribe(
                (success) => {
                    this.getAll();
                    this.notifService.showSuccessNotif(success);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    reorder(categories: Category[]): void {
        this.submitButtonService.setDisabled(this.reorderSubmitButton);
        this.apiService.post(this.route + '/reorder', {
            "categories": categories
        })
            .pipe(
                catchError(error => {
                    this.notifService.showErrorNotif(error);
                    return throwError(error);
                }),
                finalize(() => {
                    this.submitButtonService.setEnabled(this.reorderSubmitButton);
                })
            )
            .subscribe(
                (success) => {
                    this.getAll();
                    this.modalService.hideCategoryReorderModal();
                    this.notifService.showSuccessNotif(success);
                }
            );
    }

    getForm(category?: Category): FormGroup {
        const name = category ? category.name : '';
        const icon = category ? category.icon : '';
        return this.formBuilder.group({
            name: [name, Validators.required],
            icon: [icon, Validators.required],
            iconCss: new FormControl()
        });
    }

}
