import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Folder } from '@models/folder.model';
import { Link } from '@models/link.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { LinkService } from '@services/link.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-shared-link',
  templateUrl: './modal-shared-link.component.html',
  styleUrls: ['./modal-shared-link.component.scss']
})
export class ModalSharedLinkComponent implements OnInit, OnDestroy {

  @Input() selectedFolder: Folder;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @ViewChild('modalSharedLink', { static: false }) childModal: ModalDirective;
  public submitButton: SubmitButton;
  public links = [];
  private links$: Subscription;
  public selectedLinkId = null;

  constructor(
    private linkService: LinkService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) {
    this.initSubscriptions();
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setSharedLinkModalModal(this.childModal);
  }

  ngOnDestroy() {
    if (this.links$) {
      this.links$.unsubscribe();
    }
  }

  onModalShown() {
    this.linkService.getAll();
    this.submitButton = this.submitButtonService.getSubmitButtonInstance({
      text: 'Ajouter',
      objectName: 'le lien partagé',
      icon: faShareAlt
    });
    this.linkService.setSharedSubmitButton(this.submitButton);
  }

  onModalHidden() {
    this.links = [];
    this.selectedLinkId = null;
  }

  closeModal() {
    this.childModal.hide();
  }

  onAddSharedClicked() {
    if (this.isValidButton()) {
      this.linkService.addShared({
        id: this.selectedLinkId,
        list: {
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      }, this.selectedFolder.id);
    }
  }

  isValidButton() {
    return this.selectedLinkId != null && this.selectedFolder != null;
  }

  private initSubscriptions() {
    this.links$ = this.linkService.links$.subscribe(
      (links: Link[]) => {
        if (links) {
          this.links = links;
        }
      }
    );
  }

}
