// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  baseUrl: window.location.origin, // Base URL here
  apiUrl: 'https://dev.api.bibliotheque.nrjdiags.fr/api', // your local API URL here
  predevUrl: 'https://predev2.erp.abmec.fr/api',
  pusher: {
    key: 'e9e75442bb885eeba61a',
    cluster: 'eu',
  }
  // ... more of your variables
};
