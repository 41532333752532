import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Audit } from '@models/audit.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class AuditService {

    private route = '/audits';
    public audits$ = new BehaviorSubject<Audit[]>(undefined);
    public eventsTypes$ = new BehaviorSubject<any>(undefined);
    public eventsEntities$ = new BehaviorSubject<any>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService,
        private formBuilder: FormBuilder
    ) { }

    getFiltersForm(): FormGroup {
        const today = new Date();
        const curr = new Date();
        const firstDayOfWeek = new Date(curr.setDate(today.getDate() - today.getDay() + 1));
        return this.formBuilder.group({
            dateFrom: [firstDayOfWeek, Validators.required],
            dateTo: [today, Validators.required],
            eventType: null,
            eventEntity: null,
            userId: null
        });
    }

    getForList(form: FormGroup): void {
        this.apiService.post(`${this.route}/list`, form.value)
            .subscribe(
                (audits: Audit[]) => {
                    this.audits$.next(audits);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getEventsTypes(): void {
        this.apiService.get(`${this.route}/events-types`)
            .subscribe(
                (eventsTypes: any[]) => {
                    this.eventsTypes$.next(eventsTypes);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

    getEventsEntities(): void {
        this.apiService.get(`${this.route}/events-entities`)
            .subscribe(
                (eventsEntities: any[]) => {
                    this.eventsEntities$.next(eventsEntities);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }

}
