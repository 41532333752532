<div
  class="link-container"
  (mouseenter)="onMouseOverLinks(index)"
  (mouseleave)="onMouseOverLinks(-1)">
  <ng-container *ngIf="index == currentHoverIndex">
    <ng-container *ngIf="localStorageService.isEditMode() && !link.isFavorite">
      <div class="link-btn-options">
        <button
          class="btn btn-warning btn-circle mt-0"
          title="Éditer le lien"
          (click)="onUpdateClicked($event)">
          <i class="fas fa-pen"></i>
        </button>
        <button
          *ngIf="!localStorageService.isArchived()"
          class="btn btn-pages btn-circle mt-0 ml-1"
          title="Archiver le lien"
          (click)="onArchiveClicked($event)">
          <i class="fas fa-archive color-white"></i>
        </button>
        <button
          *ngIf="localStorageService.isArchived()"
          class="btn btn-pages btn-circle mt-0 ml-1"
          title="Désarchiver le lien"
          (click)="onUnarchiveClicked($event)">
          <i class="fas fa-box-open color-white"></i>
        </button>
        <button
          class="btn btn-complete btn-circle mt-0 ml-1"
          title="Modifier la visibilité"
          (click)="onSetVisibilityClicked($event)">
          <i class="fas fa-eye color-white"></i>
        </button>
        <button
          *ngIf="link.isShared"
          class="btn btn-primary btn-circle mt-0 ml-1"
          title="Enlever le lien partagé"
          (click)="onRemoveSharedClicked($event)">
          <i class="fas fa-unlink color-white"></i>
        </button>
        <button
          class="btn btn-danger btn-circle mt-0 ml-1"
          title="Supprimer le lien"
          (click)="onDeleteClicked($event)">
          <i class="fas fa-trash color-white"></i>
        </button>
      </div>
    </ng-container>
    <div class="link-btn-favorite p-1">
      <fa-icon
        [icon]="favService.getFavoriteIcon(link.isFavorite)"
        [styles]="{'color': favService.getFavoriteIconColor(link.isFavorite)}"
        [title]="favService.getFavoriteIconTooltip(link.isFavorite)"
        (click)="onUpdateFavoriteStatus($event)">
      </fa-icon>
    </div>
  </ng-container>
  <ng-container *ngIf="!hideSharedIcon()">
    <div class="link-shared-icon p-1">
      <i class="fas fa-share-alt-square link-access-icon"></i>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center align-items-center px-2 py-1">
    <img
      height="16"
      width="16"
      src='http://www.google.com/s2/favicons?domain={{link.url}}'>
    <a
      class="pl-1 span-overflow"
      [href]="link.url"
      target="_blank">
      <span class="color-black">{{ link.name }}</span>
    </a>
  </div>
  <div
    *ngIf="link.login || link.password"
    class="px-3 pb-2">
    <div
      *ngIf="link.login"
      class="d-flex align-items-center">
      <i class="fas fa-user link-access-icon"></i>
      <span
        class="pl-1 link-access-text span-overflow"
        title="Cliquer pour copier le texte"
        (click)="onTextCopied(link.login)">
        {{ link.login }}
      </span>
    </div>
    <div
      *ngIf="link.password"
      class="d-flex align-items-center">
      <i class="fas fa-lock link-access-icon"></i>
      <span
        class="pl-1 link-access-text span-overflow"
        title="Cliquer pour copier le texte"
        (click)="onTextCopied(link.password )">
        {{ link.password }}
      </span>
    </div>
  </div>
  <ng-container *ngIf="localStorageService.isEditMode() && link.marketsTypes">
    <div class="d-flex justify-content-center mb-1">
      <i
        *ngFor="let marketType of link.marketsTypes; let i = index"
        class="fas tll-18 {{marketType.tllIcon}}"
        [ngClass]="{'ml-1': i > 0}"
        [title]="marketType.name">
      </i>
    </div>
  </ng-container>
</div>
