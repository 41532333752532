<ul class="list-group text-selection-none">
  <ng-container *ngFor="let item of items">
    <li
      class="list-group-item"
      [class.active]="activeRouteItem === item.route"
      (click)="onItemClicked(item.route)">
      <div class="d-flex align-items-center">
        <i
          *ngIf="isEditCategory"
          [class]="item.icon + ' fa-2x pr-2'">
        </i>
        <fa-icon
          *ngIf="!isEditCategory"
          [icon]="getIcon(item.route)"
          size="2x">
        </fa-icon>
        <div class="pl-1">
          <span class="text-master text-bold">{{item.name}}</span>
          <ng-container *ngIf="localStorageService.isEditMode()">
            <span class="block text-master hint-text fs-12">
              <label
                class="label"
                title="{{item.documentCount}} document(s)">
                {{item.documentCount}}
                <i class="fas fa-file"></i>
              </label>
              <label
                class="label ml-1"
                title="{{item.linkCount}} lien(s)">
                {{item.linkCount}}
                <i class="fas fa-link"></i>
              </label>
              <label
                class="label ml-1"
                title="{{item.archiveCount}} document(s) ou lien(s) archivé(s)">
                {{item.archiveCount}}
                <i class="fas fa-archive"></i>
              </label>
            </span>
          </ng-container>
        </div>
        <div
          *ngIf="item.isPinned"
          style="margin-left: auto">
          <i class="tll fa-thumbtack"></i>
        </div>
      </div>
    </li>
  </ng-container>
</ul>
