import Swal from 'sweetalert2';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MarketType } from '@models/market-type.model';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { ModalService } from '@services/utilities/modal.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';

@Component({
  selector: 'app-modal-market-type',
  templateUrl: './modal-market-type.component.html',
  styleUrls: ['./modal-market-type.component.scss']
})
export class ModalMarketTypeComponent implements OnInit, AfterViewInit {

  @ViewChild('modalMarketType', { static: false }) childModal: ModalDirective;
  public selectedMarketsTypesIds: number[] = [];
  private selectedMarketsTypes: MarketType[] = [];

  constructor(
    private router: Router,
    private modalService: ModalService,
    private localStorageService: LocalStorageService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setMarketTypeModal(this.childModal);
  }

  onModalShown() {
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
    this.selectedMarketsTypesIds = this.localStorageService.getSelectedMarketsTypesIds();
  }

  onModalHidden() {
    this.selectedMarketsTypesIds = [];
  }

  closeModal() {
    this.childModal.hide();
  }

  onSelectedMarketsTypesChange(marketsTypes: MarketType[]) {
    this.selectedMarketsTypes = marketsTypes;
  }

  onUpdateMarketsTypesClicked() {
    this.closeModal();
    this.localStorageService.setSelectedMarketsTypes(this.selectedMarketsTypes);
    Swal.fire({
      title: 'Succès !',
      text: 'La sélection des types de marchés a été mise à jour',
      icon: 'success'
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/accueil']).then(() => {
          window.location.reload();
        });;
      }
    });
  }

  isValidSubmit() {
    return this.selectedMarketsTypes.length > 0;
  }

}
