import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Category } from '@models/category.model';
import { CategoryService } from '@services/category.service';
import { AuthenticationService } from '@services/utilities/authentication.service';
import { ModalService } from '@services/utilities/modal.service';
import { LocalStorageService } from '@services/utilities/local-storage.service';
import { RootLayout } from 'app/@pages/layouts/root/root.component';
import { pagesToggleService } from 'app/@pages/services/toggler.service';
import { Subscription } from 'rxjs';
import { User } from '@models/user.model';
import { UserNotificationService } from '@services/user-notification.service';
import { PusherService } from '@services/pusher.service';

@Component({
  selector: 'app-app-with-sidebar',
  templateUrl: './app-with-sidebar.component.html',
  styleUrls: ['./../../app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppWithSidebarComponent extends RootLayout implements OnInit, OnDestroy {

  public menuLinks = [];
  public isEditMode: boolean;
  public isEditCategory = false;
  public isAdmin = false;
  public selectedMarketsTypes = [];
  public userInfos: User;
  public categories: Category[] = [];
  private categories$: Subscription;
  private homeItem = {
    label: 'ACCUEIL',
    routerLink: '/accueil',
    iconType: 'fa',
    iconName: 'th-large'
  };
  private notifications$: Subscription;
  public notifications: any;

  constructor(
    _toggler: pagesToggleService,
    _router: Router,
    public localStorageService: LocalStorageService,
    public modalService: ModalService,
    public authService: AuthenticationService,
    private categoryService: CategoryService,
    private userNotificationService: UserNotificationService,
    private pusherService: PusherService,
  ) {
    super(_toggler, _router, localStorageService);
    this.initSubscriptions();
    this.userNotificationService.getUnreadNotifications();
    this.pusherService.channel.bind('user-notify', data => {
      this.userNotificationService.getUnreadNotifications();
    });
  }


  ngOnInit() {
    this.isEditMode = this.localStorageService.isEditMode();
    this.isAdmin = this.authService.isAdmin();
    this.userInfos = this.authService.getUserInfos();
    this._menuPin = this.localStorageService.isMenuPin();
    this.toggleMenuPin(null);
    this.categoryService.getAll();
    this.selectedMarketsTypes = this.localStorageService.getSelectedMarketsTypes();
  }

  ngOnDestroy() {
    this.categories$.unsubscribe();
    this.notifications$.unsubscribe();
  }

  onAddCategoryClicked() {
    this.isEditCategory = false;
    this.modalService.showCategoryModal();
  }

  onEditCategoryClicked() {
    this.isEditCategory = true;
    this.modalService.showCategoryModal();
  }

  onReorderCategoriesClicked() {
    this.modalService.showCategoryReorderModal();
  }

  onSelectMarketTypeClicked() {
    this.modalService.showMarketTypeModal();
  }

  onSwitchModeChanged(value: boolean) {
    this.localStorageService.setEditMode(value);
  }

  onShowNotificationsModalClicked() {
    this.modalService.showNotificationsModal();
  }

  logout() {
    this.authService.logout();
  }

  private initSubscriptions() {
    this.categories$ = this.categoryService.categories$.subscribe(
      (categories) => {
        this.menuLinks = [];
        this.menuLinks.push(this.homeItem);
        if (categories) {
          this.categories = categories;
          for (let cat of categories) {
            if (cat.subcategories.length > 0) {
              let submenu = [];
              for (let subcat of cat.subcategories) {
                submenu.push({
                  label: subcat.name,
                  routerLink: `/documentation/${cat.route}/${subcat.route}`,
                  iconType: 'letter',
                  iconName: subcat.abbreviation
                });
              }
              this.menuLinks.push({
                label: cat.name,
                iconType: 'fas',
                iconName: cat.icon,
                toggle: 'close',
                submenu: submenu
              });
            } else {
              this.menuLinks.push({
                label: cat.name,
                routerLink: `/documentation/${cat.route}`,
                iconType: 'fas',
                iconName: cat.icon
              });
            }
          }
        }
      }
    );

    this.notifications$ = this.userNotificationService.notifications$.subscribe(
      (data) => {
        if (data) {
          this.notifications = data;
          console.log(data);
        }
      }
    );
  }

}
