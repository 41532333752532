<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body p-2">
    <div class="d-flex">
      <span class="span-large">
        <b>
          Dossiers {{ localStorageService.isArchived() ? 'Archivés' : '' }}
        </b>
      </span>
      <ng-container *ngIf="localStorageService.isEditMode()">
        <div class="ml-auto">
          <button
            class="btn btn-success btn-circle"
            title="Ajouter un dossier"
            (click)="onAddClicked()">
            <i class="fas fa-plus"></i>
          </button>
          <button
            class="btn btn-warning btn-circle ml-1"
            title="Modifier le dossier sélectionné"
            [disabled]="selectedFolder == null"
            (click)="onUpdateClicked()">
            <i class="fas fa-pen"></i>
          </button>
          <button
            *ngIf="selectedFolder != null && !selectedFolder.isPinned"
            class="btn btn-pages btn-circle ml-1"
            title="Épingler le dossier sélectionné"
            (click)="onPinClicked()">
            <i class="tll fa-thumbtack"></i>
          </button>
          <button
            *ngIf="selectedFolder != null && selectedFolder.isPinned"
            class="btn btn-pages btn-circle ml-1"
            title="Désépingler le dossier sélectionné"
            (click)="onUnpinClicked()">
            <i class="tll fa-unthumbtack"></i>
          </button>
          <button
            class="btn btn-danger btn-circle ml-1"
            title="Supprimer le dossier sélectionné"
            [disabled]="selectedFolder == null"
            (click)="onDeleteClicked()">
            <i class="fas fa-trash"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body">
    <list-view
      [items]="folders"
      (itemSelected)="onFolderSelected($event)">
    </list-view>
  </div>
</div>
<app-modal-folder
  [folder]="selectedFolder"
  [category]="category"
  [subcategory]="subcategory"
  [isEdit]="isEditFolder">
</app-modal-folder>
