import { AuthenticationService } from './authentication.service';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()
export class ApiService {
    public apiUrl;
    constructor(
        private http: HttpClient,
        private auth: AuthenticationService,
    ) {
        this.apiUrl = environment.apiUrl;
    }

    get<T>(url: string, params: HttpParams = new HttpParams()): Observable<T> {
        return this.http.get<T>(`${this.apiUrl}${url}`, {
            headers: this.headers,
            params,
        });
    }

    post<T, D>(url: string, data: D): Observable<T> {
        return this.http.post<T>(`${this.apiUrl}${url}`, JSON.stringify(data), {
            headers: this.headers
        });
    }

    postBlob(url: string, data: any): Observable<HttpResponse<any>> {
        return this.http.post(`${this.apiUrl}${url}`, JSON.stringify(data), {
            headers: this.headers,
            observe: 'response',
            responseType: 'blob' as 'json'
        });
    }

    put<T, D>(url: string, data: D): Observable<T> {
        return this.http.put<T>(`${this.apiUrl}${url}`, JSON.stringify(data), {
            headers: this.headers
        });
    }

    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(`${this.apiUrl}${url}`, {
            headers: this.headers,
        });
    }

    deleteWithBody(url: string, data: any): Observable<any> {
        const httpOptions = {
            headers: this.headers,
            body: JSON.stringify(data)
        };
        return this.http.delete(`${this.apiUrl}${url}`, httpOptions);
    }

    getFile(url: string): Observable<Blob> {
        return this.http.get(`${this.apiUrl}${url}`, {
            responseType: 'blob'
        });
    }

    importFile<T, D>(url: string, data: D): Observable<T> {
        return this.http.post<T>(`${this.apiUrl}${url}`, data);
    }

    get headers(): HttpHeaders {
        const headersConfig = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        };

        return new HttpHeaders(headersConfig);
    }

    public getApiUrl() {
        return this.apiUrl;
    }

    public getBearerToken(): string {
        return this.auth.getToken();
    }
}
