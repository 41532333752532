import { Injectable } from '@angular/core';
import { User } from '@models/user.model';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './utilities/api.service';
import { NotifService } from './utilities/notif.service';

@Injectable()
export class UserService {

    private route = '/users';
    public users$ = new BehaviorSubject<User[]>(undefined);

    constructor(
        private apiService: ApiService,
        private notifService: NotifService
    ) { }

    getForSelect(): void {
        this.apiService.get(`${this.route}/select`)
            .subscribe(
                (users: User[]) => {
                    this.users$.next(users);
                },
                (error) => {
                    this.notifService.showErrorNotif(error);
                }
            );
    }
}
