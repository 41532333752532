import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { Category } from '@models/category.model';
import { Document } from '@models/document.model';
import { Subcategory } from '@models/subcategory.model';
import { SubmitButton } from '@models/submit-button.model';
import { DocumentService } from '@services/document.service';
import { ModalService } from '@services/utilities/modal.service';
import { SubmitButtonService } from '@services/utilities/submit-button.service';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-document',
  templateUrl: './modal-document.component.html',
  styleUrls: ['./modal-document.component.scss']
})
export class ModalDocumentComponent implements OnInit, AfterViewInit {

  @Input() document: Document;
  @Input() category: Category;
  @Input() subcategory: Subcategory;
  @ViewChild('modalDocument', { static: false }) childModal: ModalDirective;
  public form: FormGroup;
  public submitButton: SubmitButton;

  constructor(
    private documentService: DocumentService,
    private modalService: ModalService,
    private submitButtonService: SubmitButtonService
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.modalService.setDocumentModal(this.childModal);
  }

  onModalShown() {
    this.submitButton = this.submitButtonService.determinateSubmitButton(this.document, 'le document');
    this.documentService.setSubmitButton(this.submitButton);
    this.form = this.documentService.getForm(this.document);
  }

  onModalHidden() {
  }

  closeModal() {
    this.childModal.hide();
  }

  submitForm() {
    if (this.isValidForm()) {
      this.documentService.update({
        id: this.document.id,
        form: this.form,
        list: {
          categoryRoute: this.category.route,
          subcategoryRoute: this.subcategory ? this.subcategory.route : null
        }
      })
    }
  }

  isValidForm() {
    return this.form.valid && this.form.dirty;
  }

}
