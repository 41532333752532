<div
  bsModal
  #modalImportDocuments="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div class="modal-dialog modal-xxl">
    <div class="modal-content">
      <ng-container>
        <div class="modal-header">
          <h4
            id="dialog-sizes-name1"
            class="modal-title pull-left">
            Dépôt de documents
          </h4>
          <button
            type="button"
            class="close pull-right"
            (click)="closeModal()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mt-4">
          <ngx-dropzone
            class="custom-dropzone"
            (change)="onImport($event)">
            <ngx-dropzone-label>
              Faites glisser ou cliquez ici pour télécharger des documents
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              class="custom-dropzone-preview"
              *ngFor="let f of files"
              [removable]="false">
              <ngx-dropzone-label class="custom-dropzone-label">{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </div>
      </ng-container>
    </div>
  </div>
</div>
