<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body p-2">
    <div class="d-flex">
      <span class="span-large">
        <b>
          Documents {{ localStorageService.isArchived() ? 'Archivés' : '' }}
        </b>
      </span>
      <ng-container *ngIf="localStorageService.isEditMode()">
        <div class="ml-auto">
          <button
            class="btn btn-success btn-circle"
            title="Importer un document"
            [disabled]="selectedFolder == null"
            (click)="onImportDocumentsClicked()">
            <i class="fas fa-upload"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body">
    <div class="flex-container">
      <ng-container *ngFor="let doc of getThumbnails(); let i = index">
        <thumbnail
          class="m-2"
          [index]="i"
          [currentHoverIndex]="currentHoverIndex"
          [document]="doc"
          (mouseOverThumbnails)="currentHoverIndex = $event"
          (updateButtonClicked)="onUpdateDocumentClicked($event)"
          (archiveButtonClicked)="onArchiveDocumentClicked($event)"
          (unarchiveButtonClicked)="onUnarchiveDocumentClicked($event)"
          (visibilityButtonClicked)="onSetVisibilityDocumentClicked($event)"
          (deleteButtonClicked)="onDeleteDocumentClicked($event)"
          (favoriteButtonClicked)="onUpdateFavoriteStatus($event)"
          *ngIf="(localStorageService.isArchived() && doc.isArchived) || (!localStorageService.isArchived() && !doc.isArchived)">
        </thumbnail>
      </ng-container>
    </div>
  </div>
</div>
<app-modal-document
  [document]="selectedDocument"
  [category]="category"
  [subcategory]="subcategory">
</app-modal-document>
<app-modal-visibility-document
  [document]="selectedDocument"
  [category]="category"
  [subcategory]="subcategory">
</app-modal-visibility-document>
<app-modal-import-documents
  [category]="category"
  [subcategory]="subcategory"
  [folder]="selectedFolder">
</app-modal-import-documents>
