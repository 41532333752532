<div class="card">
  <div class="card-body p-2">
    <div class="d-flex">
      <span>
        {{ getCategoryBreadcrumb() }}
        <b>{{ getFolderBreadcrumb() }}</b>
      </span>
      <div class="ml-auto">
        <nz-switch
          [ngModel]="isArchived"
          nzCheckedChildren="ARCHIVÉS"
          nzUnCheckedChildren="NON ARCHIVÉS"
          (ngModelChange)="onArchiveModeChange($event)">
        </nz-switch>
      </div>
    </div>
  </div>
</div>
