<div
  bsModal
  #modalCategory="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-sizes-name1"
  (onShown)="onModalShown()"
  (onHidden)="onModalHidden()"
  [config]="{backdrop: 'static'}">
  <div
    class="modal-dialog"
    [ngClass]="{'modal-lg': !isEdit, 'modal-xxl': isEdit}">
    <div class="modal-content">
      <ng-container *ngIf="form != null">
        <div class="modal-header">
          <h4
            id="dialog-sizes-name1"
            class="modal-title pull-left">
            {{isEdit ? 'Édition' : 'Ajout'}} d'une catégorie
          </h4>
          <button
            type="button"
            class="close pull-right"
            (click)="closeModal()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mt-4">
          <ng-container *ngIf="isEdit">
            <div class="row">
              <div class="col">
                <list-view
                  [isEditCategory]="isEdit"
                  [items]="categories"
                  (itemSelected)="onCategorySelected($event)">
                </list-view>
              </div>
              <div class="col">
                <ng-container *ngTemplateOutlet="categoryContent"></ng-container>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="!isEdit">
            <ng-container *ngTemplateOutlet="categoryContent"></ng-container>
          </ng-container>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            *ngIf="isEdit"
            class="btn btn-danger btn-lg btn-icon-left mt-0"
            (click)="onDeleteClicked()">
            <i class="fas fa-trash"></i>
            <span>Supprimer la catégorie</span>
          </button>
          <submit-button
            [isValidForm]="isValidForm()"
            [submitButton]="submitButton"
            (onButtonClicked)="submitForm()">
          </submit-button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #categoryContent>
  <form
    autocomplete="off"
    [formGroup]="form">
    <div
      class="form-group"
      ngxValidationErrorsField>
      <label class="required">Nom</label>
      <input
        type="text"
        class="form-control input-sm"
        formControlName="name">
    </div>
    <div
      class="form-group"
      ngxValidationErrorsField>
      <label
        class="required"
        (click)="updateLinkDivVisibility()">
        Icône
        <i
          class="fas fa-question-circle"
          style="cursor: pointer;">
        </i>
      </label>
      <input
        type="text"
        class="form-control input-sm"
        placeholder="ex: fas fa-home"
        formControlName="icon">
      <div *ngIf="isLinkDivVisible">
        <span class="help">
          Liens:
          <a
            class="pl-1"
            href="https://fontawesome.com/icons?d=gallery&m=free"
            target="_blank">
            FontAwesome
          </a>
          <b>OU</b>
          <a
            class="pl-1"
            href="https://telechargement.pmbsoftware.fr/tll/index.html"
            target="_blank">
            TLL icons
          </a>
        </span>
      </div>
    </div>
  </form>
  <div style="background: #ececec;">
    <div class="d-flex justify-content-center pt-2">
      <h5>Gestion des sous-catégories</h5>
    </div>
    <div class="d-flex justify-content-center mb-2">
      <button
        class="btn btn-success btn-sm btn-icon-left"
        (click)="onAddSubcategoryClicked()">
        <i class="fas fa-plus"></i>
        Ajouter une sous-catégorie
      </button>
    </div>
    <div
      cdkDropList
      class="cdk-list-container"
      (cdkDropListDropped)="drop($event)">
      <div
        class="cdk-item-box"
        cdkDrag
        *ngFor="let subcat of subcategories">
        <div class="col form-group">
          <label class="required">Nom</label>
          <input
            type="text"
            class="form-control input-sm"
            [(ngModel)]="subcat.name">
        </div>
        <div class="col form-group">
          <div class="row">
            <div class="col-8">
              <label class="required">Abréviation (2 lettres)</label>
              <input
                class="form-control input-sm"
                type="text"
                maxlength="2"
                [(ngModel)]="subcat.abbreviation">
            </div>
            <div class="col d-flex justify-content-end align-items-end">
              <button
                class="btn btn-danger"
                title="Supprimer la sous-catégorie"
                nz-popconfirm
                nzPopconfirmTitle="Êtes-vous sûr(e) ?"
                (nzOnConfirm)="deleteRowItem(subcat.rowId)">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
