<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body p-2">
    <div class="d-flex">
      <span class="span-large">
        <b>
          Liens {{ localStorageService.isArchived() ? 'Archivés' : '' }}
        </b>
      </span>
      <ng-container *ngIf="localStorageService.isEditMode()">
        <div class="ml-auto">
          <button
            class="btn btn-success btn-circle"
            title="Ajouter un lien"
            [disabled]="selectedFolder == null"
            (click)="onAddLinkClicked()">
            <i class="fas fa-plus"></i>
          </button>
          <button
            class="btn btn-pages btn-circle ml-1"
            title="Ajouter un lien partagé"
            [disabled]="selectedFolder == null"
            (click)="onAddSharedLinkClicked()">
            <i class="fas fa-share-alt"></i>
          </button>
        </div>
      </ng-container>
    </div>
  </div>
</div>
<div
  class="card"
  [ngClass]="{'card-archived': localStorageService.isArchived()}">
  <div class="card-body">
    <div class="flex-container">
      <ng-container *ngFor="let link of getLinks(); let i = index">
        <clickable-link
          class="m-2"
          [index]="i"
          [currentHoverIndex]="currentHoverIndex"
          [link]="link"
          (mouseOverLinks)="currentHoverIndex = $event"
          (updateButtonClicked)="onUpdateLinkClicked($event)"
          (archiveButtonClicked)="onArchiveLinkClicked($event)"
          (unarchiveButtonClicked)="onUnarchiveLinkClicked($event)"
          (visibilityButtonClicked)="onSetVisibilityLinkClicked($event)"
          (removeSharedButtonClicked)="onRemoveSharedLinkClicked($event)"
          (deleteButtonClicked)="onDeleteLinkClicked($event)"
          (favoriteButtonClicked)="onUpdateFavoriteStatus($event)"
          *ngIf="(localStorageService.isArchived() && link.isArchived) || (!localStorageService.isArchived() && !link.isArchived)">
        </clickable-link>
      </ng-container>
    </div>
  </div>
</div>
<app-modal-link
  [link]="selectedLink"
  [selectedFolder]="selectedFolder"
  [category]="category"
  [subcategory]="subcategory">
</app-modal-link>
<app-modal-visibility-link
  [link]="selectedLink"
  [category]="category"
  [subcategory]="subcategory">
</app-modal-visibility-link>
<app-modal-shared-link
  [selectedFolder]="selectedFolder"
  [category]="category"
  [subcategory]="subcategory">
</app-modal-shared-link>
